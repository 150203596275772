<template>
  <div>
    <div class="navigation-sidebar" :class="[sidebarDefault ? '' : barClosed ? 'shrink-sidebar' : 'expand-sidebar']">
    <ul class="nav sidebar-container">
        <svg v-if="!changeLogo" class="hub-logo" width="620" height="150" viewBox="0 0 620 150" fill="none"
          xmlns="http://www.w3.org/2000/svg" :class="[barClosed ? 'adjust-img' : 'reverse-adjust']">
          <g clip-path="url(#clip0_1601_1079)">
            <path
              d="M95.7918 63.8516C97.7745 62.9792 100 64.4314 100 66.5976V104.042C100 105.232 99.2971 106.309 98.2082 106.788L1.40274 149.383C0.741836 149.674 7.62939e-06 149.19 7.62939e-06 148.467V107.958C7.62939e-06 106.768 0.702923 105.691 1.79179 105.212L95.7918 63.8516Z"
              fill="#0DCE9A" />
            <path
              d="M98.5973 0.617204C99.2582 0.326404 100 0.810465 100 1.53252V42.0424C100 43.2321 99.2971 44.3093 98.2082 44.7884L4.20821 86.1484C2.22549 87.0208 0 85.5686 0 83.4024V45.9576C0 44.768 0.702915 43.6907 1.79178 43.2116L98.5973 0.617204Z"
              fill="#0DCE9A" />
            <path
              d="M162.588 101.4C156.604 101.4 151.541 100 147.398 97.2C143.321 94.4 140.855 89.9667 140 83.9L147.398 82.2C147.924 85.4 148.91 87.9333 150.357 89.8C151.804 91.6 153.579 92.9 155.684 93.7C157.854 94.4333 160.155 94.8 162.588 94.8C166.205 94.8 169.066 94.0667 171.17 92.6C173.274 91.1333 174.326 89.1667 174.326 86.7C174.326 84.1667 173.307 82.4 171.269 81.4C169.296 80.3333 166.567 79.4667 163.082 78.8L159.037 78.1C155.947 77.5667 153.119 76.7333 150.554 75.6C147.99 74.4667 145.951 72.9333 144.439 71C142.926 69.0667 142.17 66.6333 142.17 63.7C142.17 59.3 143.847 55.8667 147.201 53.4C150.554 50.9333 154.993 49.7 160.517 49.7C165.975 49.7 170.381 50.9667 173.735 53.5C177.154 55.9667 179.357 59.4667 180.343 64L173.044 65.9C172.452 62.3667 171.038 59.9 168.803 58.5C166.567 57.0333 163.805 56.3 160.517 56.3C157.229 56.3 154.631 56.9333 152.724 58.2C150.817 59.4 149.864 61.2 149.864 63.6C149.864 65.9333 150.752 67.6667 152.527 68.8C154.368 69.8667 156.801 70.6667 159.826 71.2L163.871 71.9C167.29 72.5 170.348 73.3333 173.044 74.4C175.806 75.4 177.976 76.8667 179.554 78.8C181.198 80.7333 182.02 83.2667 182.02 86.4C182.02 91.2 180.245 94.9 176.694 97.5C173.208 100.1 168.507 101.4 162.588 101.4Z"
              fill="#1E293B" />
            <path
              d="M189.255 100V51.1H197.047V100H189.255ZM193.2 43.5C191.491 43.5 190.044 42.9333 188.86 41.8C187.742 40.6667 187.183 39.2333 187.183 37.5C187.183 35.7 187.742 34.2333 188.86 33.1C190.044 31.9667 191.491 31.4 193.2 31.4C194.91 31.4 196.324 31.9667 197.442 33.1C198.56 34.2333 199.119 35.7 199.119 37.5C199.119 39.2333 198.56 40.6667 197.442 41.8C196.324 42.9333 194.91 43.5 193.2 43.5Z"
              fill="#1E293B" />
            <path
              d="M207.514 100V51.1H215.11V57.2H216.491C217.411 55.4667 218.924 53.8667 221.028 52.4C223.132 50.9333 226.091 50.2 229.906 50.2C233.654 50.2 236.679 51.0333 238.98 52.7C241.348 54.3667 243.057 56.4 244.11 58.8H245.49C246.608 56.4 248.285 54.3667 250.521 52.7C252.823 51.0333 256.012 50.2 260.089 50.2C263.311 50.2 266.139 50.8667 268.572 52.2C271.005 53.5333 272.912 55.4667 274.293 58C275.74 60.4667 276.463 63.4333 276.463 66.9V100H268.671V67.6C268.671 64.3333 267.717 61.7667 265.81 59.9C263.969 57.9667 261.371 57 258.018 57C254.467 57 251.54 58.1667 249.239 60.5C247.003 62.8333 245.885 66.2 245.885 70.6V100H238.093V67.6C238.093 64.3333 237.139 61.7667 235.232 59.9C233.391 57.9667 230.793 57 227.44 57C223.889 57 220.962 58.1667 218.661 60.5C216.425 62.8333 215.307 66.2 215.307 70.6V100H207.514Z"
              fill="#1E293B" />
            <path
              d="M286.46 120V51.1H294.055V59.2H295.436C296.686 56.7333 298.692 54.5333 301.453 52.6C304.281 50.6667 308.194 49.7 313.191 49.7C317.334 49.7 321.115 50.7333 324.535 52.8C328.02 54.8 330.782 57.6667 332.821 61.4C334.925 65.1333 335.977 69.6333 335.977 74.9V76.2C335.977 81.4 334.958 85.9 332.919 89.7C330.881 93.5 328.119 96.4 324.634 98.4C321.214 100.4 317.4 101.4 313.191 101.4C309.838 101.4 306.977 100.933 304.61 100C302.308 99.1333 300.434 98 298.987 96.6C297.541 95.2 296.423 93.7667 295.634 92.3H294.253V120H286.46ZM311.12 94.4C316.118 94.4 320.162 92.8 323.253 89.6C326.409 86.3333 327.987 81.8 327.987 76V75.1C327.987 69.3 326.409 64.8 323.253 61.6C320.162 58.3333 316.118 56.7 311.12 56.7C306.188 56.7 302.111 58.3333 298.889 61.6C295.732 64.8 294.154 69.3 294.154 75.1V76C294.154 81.8 295.732 86.3333 298.889 89.6C302.111 92.8 306.188 94.4 311.12 94.4Z"
              fill="#1E293B" />
            <path d="M344.021 100V30H351.814V100H344.021Z" fill="#1E293B" />
            <path
              d="M384.376 101.4C379.51 101.4 375.236 100.367 371.553 98.3C367.871 96.1667 365.01 93.2 362.972 89.4C360.933 85.6 359.914 81.2 359.914 76.2V75C359.914 69.9333 360.933 65.5 362.972 61.7C365.01 57.9 367.838 54.9667 371.455 52.9C375.071 50.7667 379.214 49.7 383.883 49.7C388.421 49.7 392.432 50.7 395.917 52.7C399.402 54.6333 402.131 57.4333 404.104 61.1C406.077 64.7667 407.063 69.0667 407.063 74V77.6H367.706C367.904 82.8667 369.581 87 372.737 90C375.893 92.9333 379.839 94.4 384.574 94.4C388.716 94.4 391.906 93.4333 394.142 91.5C396.377 89.5667 398.087 87.2667 399.271 84.6L405.978 87.9C404.992 89.9667 403.611 92.0333 401.835 94.1C400.126 96.1667 397.857 97.9 395.029 99.3C392.267 100.7 388.716 101.4 384.376 101.4ZM367.805 71.1H399.172C398.909 66.5667 397.364 63.0333 394.536 60.5C391.774 57.9667 388.223 56.7 383.883 56.7C379.477 56.7 375.861 57.9667 373.033 60.5C370.205 63.0333 368.463 66.5667 367.805 71.1Z"
              fill="#1E293B" />
            <path
              d="M434.169 101.4C428.185 101.4 423.121 100 418.979 97.2C414.902 94.4 412.436 89.9667 411.581 83.9L418.979 82.2C419.505 85.4 420.491 87.9333 421.938 89.8C423.384 91.6 425.16 92.9 427.264 93.7C429.434 94.4333 431.736 94.8 434.169 94.8C437.786 94.8 440.646 94.0667 442.751 92.6C444.855 91.1333 445.907 89.1667 445.907 86.7C445.907 84.1667 444.888 82.4 442.849 81.4C440.876 80.3333 438.147 79.4667 434.662 78.8L430.618 78.1C427.527 77.5667 424.7 76.7333 422.135 75.6C419.57 74.4667 417.532 72.9333 416.019 71C414.507 69.0667 413.751 66.6333 413.751 63.7C413.751 59.3 415.428 55.8667 418.781 53.4C422.135 50.9333 426.574 49.7 432.098 49.7C437.556 49.7 441.961 50.9667 445.315 53.5C448.735 55.9667 450.938 59.4667 451.924 64L444.625 65.9C444.033 62.3667 442.619 59.9 440.383 58.5C438.147 57.0333 435.385 56.3 432.098 56.3C428.81 56.3 426.212 56.9333 424.305 58.2C422.398 59.4 421.445 61.2 421.445 63.6C421.445 65.9333 422.332 67.6667 424.108 68.8C425.949 69.8667 428.382 70.6667 431.407 71.2L435.451 71.9C438.871 72.5 441.929 73.3333 444.625 74.4C447.387 75.4 449.557 76.8667 451.135 78.8C452.779 80.7333 453.601 83.2667 453.601 86.4C453.601 91.2 451.825 94.9 448.274 97.5C444.789 100.1 440.087 101.4 434.169 101.4Z"
              fill="#1E293B" />
            <path
              d="M459.553 100V30H471.982V56.5H473.757C474.283 55.4333 475.105 54.3667 476.223 53.3C477.341 52.2333 478.821 51.3667 480.662 50.7C482.569 49.9667 484.969 49.6 487.862 49.6C491.676 49.6 494.997 50.5 497.825 52.3C500.718 54.0333 502.954 56.4667 504.532 59.6C506.111 62.6667 506.9 66.2667 506.9 70.4V100H494.471V71.4C494.471 67.6667 493.551 64.8667 491.709 63C489.934 61.1333 487.369 60.2 484.016 60.2C480.201 60.2 477.242 61.5 475.138 64.1C473.034 66.6333 471.982 70.2 471.982 74.8V100H459.553Z"
              fill="#1E293B" />
            <path
              d="M532.289 100.8C528.475 100.8 525.121 99.9333 522.228 98.2C519.4 96.4 517.198 93.9333 515.619 90.8C514.041 87.6667 513.252 84.0667 513.252 80V50.4H525.68V79C525.68 82.7333 526.568 85.5333 528.344 87.4C530.185 89.2667 532.782 90.2 536.136 90.2C539.95 90.2 542.909 88.9333 545.014 86.4C547.118 83.8 548.17 80.2 548.17 75.6V50.4H560.599V100H548.367V93.5H546.592C545.803 95.1667 544.323 96.8 542.153 98.4C539.983 100 536.695 100.8 532.289 100.8Z"
              fill="#1E293B" />
            <path
              d="M597.905 101.4C593.499 101.4 590.112 100.633 587.745 99.1C585.378 97.5667 583.635 95.8667 582.517 94H580.742V100H568.51V30H580.939V56.1H582.715C583.438 54.9 584.391 53.7667 585.575 52.7C586.824 51.6333 588.436 50.7667 590.408 50.1C592.447 49.3667 594.946 49 597.905 49C601.85 49 605.5 50 608.854 52C612.208 53.9333 614.904 56.8 616.942 60.6C618.981 64.4 620 69 620 74.4V76C620 81.4 618.981 86 616.942 89.8C614.904 93.6 612.208 96.5 608.854 98.5C605.5 100.433 601.85 101.4 597.905 101.4ZM594.157 90.4C597.971 90.4 601.16 89.1667 603.725 86.7C606.289 84.1667 607.572 80.5 607.572 75.7V74.7C607.572 69.9 606.289 66.2667 603.725 63.8C601.226 61.2667 598.036 60 594.157 60C590.343 60 587.153 61.2667 584.589 63.8C582.024 66.2667 580.742 69.9 580.742 74.7V75.7C580.742 80.5 582.024 84.1667 584.589 86.7C587.153 89.1667 590.343 90.4 594.157 90.4Z"
              fill="#1E293B" />
          </g>
          <defs>
            <clipPath id="clip0_1601_1079">
              <rect width="620" height="150" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <img v-else class="mb-3 hub-icon" src="../../assets/images/SimplesHub Logo.png"
          :class="[barClosed ? 'show-icon' : 'hide-icon']">
        <div class="divisor-container">
          <div class="line">
            <div class="arrow-button" :class="[sidebarDefault ? '' : barClosed ? 'shrink-line' : 'expand-line']"
              @click="changeSidebar()">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8L10 12L14 16" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      <div class="row user-info" :class="[barClosed ? 'shrink-container' : 'expand-container']">
        <template v-if="!user">
          <div class="icon-loading">
              <i class="fas fa-circle-notch fa-spin"></i>
          </div>
          <div>
            <div class="name-loading"></div>
            <div class="emissions-loading"></div>
          </div>
        </template>
        <template v-else>
          <div class="names-container">
          <template v-if="user.url">
            <div class="photo" :style="{ backgroundImage: `url(${user.url})` }"></div>
          </template>
          <template v-else>
            <i class="fas fa-user"></i>
          </template>
          <div>
            <template v-if="user && user.name">
              <div class="affiliate-info">
                <p class="name-user" :class="[barClosed ? 'hiding-text' : 'show-text']">{{ this.capitalize(user.name) }}</p>
                <p v-if="user.position" class="emissions-user" :class="[barClosed ? 'hiding-text' : 'show-text']">{{user.position.toUpperCase()}}</p>
                <p v-else class="emissions-user" :class="[barClosed ? 'hiding-text' : 'show-text']">Operador</p>
              </div>
            </template>
          </div>
          </div>
        </template>
      </div>
      <!-- <li class="nav-item">
        <router-link
          exact
          class="nav-link"
          exact-active-class="active"
          :to="{ name: 'monitor' }"
        >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.327 13.363C15.472 13.363 14.663 13.174 13.936 12.837L10.441 13.579L11.172 10.077C10.833 9.348 10.643 8.535 10.643 7.678C10.643 4.543 13.185 2 16.322 2C19.459 2 22 4.543 22 7.678C22 10.813 19.457 13.356 16.322 13.356" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2 18.371L14.23 17.401C13.745 16.916 12.96 16.916 12.476 17.401L11.714 18.163C11.544 18.333 11.286 18.39 11.066 18.294C9.961 17.81 8.87001 17.077 7.89701 16.104C6.92801 15.135 6.19701 14.049 5.71301 12.948C5.61201 12.721 5.671 12.454 5.847 12.277L6.53 11.594C7.085 11.039 7.08501 10.255 6.60001 9.77L5.629 8.8C4.983 8.154 3.937 8.154 3.291 8.8L2.752 9.339C2.139 9.952 1.884 10.836 2.049 11.712C2.457 13.872 3.71301 16.238 5.737 18.263C7.761 20.288 10.127 21.543 12.288 21.951C13.164 22.116 14.048 21.861 14.661 21.248L15.2 20.71C15.845 20.064 15.845 19.017 15.2 18.371Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.6724 7.75464C13.6919 7.77417 13.6919 7.80582 13.6724 7.82535C13.6529 7.84488 13.6212 7.84488 13.6017 7.82535C13.5822 7.80582 13.5822 7.77417 13.6017 7.75464C13.6212 7.73511 13.6529 7.73511 13.6724 7.75464" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.2784 7.75464C16.2979 7.77417 16.2979 7.80582 16.2784 7.82535C16.2589 7.84488 16.2272 7.84488 16.2077 7.82535C16.1882 7.80582 16.1882 7.77417 16.2077 7.75464C16.2272 7.73511 16.2589 7.73511 16.2784 7.75464" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.8834 7.75464C18.9029 7.77417 18.9029 7.80582 18.8834 7.82535C18.8639 7.84488 18.8322 7.84488 18.8127 7.82535C18.7932 7.80582 18.7932 7.77417 18.8127 7.75464C18.8322 7.73511 18.8639 7.73511 18.8834 7.75464" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
            Monitoramento
          </router-link>
      </li> -->
      <p v-if="!barClosed" class="division-title"
          :class="[barClosed ? 'shrink-division-title' : 'expand-division-title']">
          MENU</p>
      <p v-else class="division-line" :class="[barClosed ? 'shrink-division-title' : 'expand-division-title']"
        style="color: #E2E8F0;"></p>
      <li class="nav-item">
        <RouteLink :route="'users'" :routeLabel="'Clientes'" :barClosed="barClosed">
          <Icon icon="document-user" />
        </RouteLink>
      </li>
      <li class="nav-item">
        <RouteLink :route="'questions'" :routeLabel="'Perguntas'" :barClosed="barClosed">
          <Icon icon="help" />
        </RouteLink>
      </li>
      <li class="nav-item">
        <RouteLink :route="'trainings'" :routeLabel="'Treinamentos'" :barClosed="barClosed">
          <Icon icon="headset" />
        </RouteLink>
      </li>
      <li v-if="user.position && (user.position == 'adm' || user.position == 'master')" class="nav-item" style="margin-bottom: 16px;">
        <RouteLink :route="'finance'" :routeLabel="'Financeiro'" :barClosed="barClosed">
          <Icon icon="coin" />
        </RouteLink>
      </li>
      <!-- <li v-if="user.position && (user.position == 'adm' || user.position == 'master')" class="nav-item">
        <router-link
          exact
          class="nav-link"
          exact-active-class="active"
          :to="{ name: 'management' }"
        >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.4692 3.02278C13.8329 4.38649 13.8329 6.5975 12.4692 7.96121C11.1055 9.32492 8.89449 9.32492 7.53078 7.96121C6.16707 6.5975 6.16707 4.38649 7.53078 3.02278C8.89449 1.65907 11.1055 1.65907 12.4692 3.02278" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.363 12.796C13.064 12.277 11.54 11.991 10 11.991C5.952 11.991 2 13.958 2 16.983V17.983C2 18.535 2.448 18.983 3 18.983H12.413" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17 22C14.239 22 12 19.762 12 17C12 14.296 14.3 11.997 17.004 12C19.764 12.002 22 14.24 22 17C22 19.761 19.762 22 17 22" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.55 19.062H15.45C15.174 19.062 14.95 18.838 14.95 18.562V17.312C14.95 17.036 15.174 16.812 15.45 16.812H18.55C18.826 16.812 19.05 17.036 19.05 17.312V18.562C19.05 18.839 18.826 19.062 18.55 19.062Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.75 16.812V15.687C15.75 14.997 16.31 14.437 17 14.437V14.437C17.69 14.437 18.25 14.997 18.25 15.687V16.812" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Gestão</router-link>
      </li> -->
      <p v-if="!barClosed" class="division-title"
          :class="[barClosed ? 'shrink-division-title' : 'expand-division-title']">
          CONTA</p>
      <p v-else class="division-line" :class="[barClosed ? 'shrink-division-title' : 'expand-division-title']"
        style="color: #E2E8F0;"></p>
      <li class="nav-item">
        <RouteLink :route="'settings'" :routeLabel="'Configurações'" :barClosed="barClosed">
          <Icon icon="gear" />
        </RouteLink>
      </li>
      <li class="nav-item">
        <RouteLink @click.native="doLogout()" :routeLabel="'Sair'" :barClosed="barClosed">
          <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8 23.8703V9.60353C13.8 8.77553 13.374 8.00633 12.672 7.56833L7.87201 4.56833C6.27361 3.56993 4.20001 4.71833 4.20001 6.60353V20.8691C4.20001 21.6971 4.62601 22.4663 5.32801 22.9043L10.128 25.9043C11.7264 26.9039 13.8 25.7543 13.8 23.8703Z" stroke="#DE6767" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.6 13.8H25.8" stroke="#DE6767" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M23.4 16.2L25.8 13.8L23.4 11.4" stroke="#DE6767" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.8 23.3999H18.6C19.926 23.3999 21 22.3259 21 20.9999V19.7999" stroke="#DE6767" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 7.79995V6.59995C21 5.27395 19.926 4.19995 18.6 4.19995H6.60001" stroke="#DE6767" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </RouteLink>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
import Vue from 'vue'
import RouteLink from './RouteLink.vue';
import Icon from '../ui/Icon.vue';

export default {
  components: {
    RouteLink,
    Icon,
  },
  data: () => ({
    user: '',
    sidebarDefault: true,
    barClosed: false,
    changeLogo: false,
  }),
  mounted () {
    this.getData()
    this.$root.$on('verifyActived', this.verifyActived)
  },
  methods: {
    async changeSidebar () {
      this.$emit('handleChange')
      this.sidebarDefault = false
      if (this.barClosed === true) {
        this.barClosed = false
        setTimeout(() => {
          this.changeLogo = false
        }, 300)
        // this.changeLogo = false
      } else {
        this.barClosed = true
        setTimeout(() => {
          this.changeLogo = true
        }, 500)
      }
    },
    async getData () {
      await this.getUser()
    },
    capitalize (data) {
      var capitalized = []
      var dataTemp = data + ''
      dataTemp.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      if (capitalized[1]) {
        return capitalized[0] + ' ' + capitalized[1]
      } else {
        return capitalized[0]
      }
    },
    async doLogout () {
      this.$root.$emit('Spinner::show')
      await this.$firebase.auth().signOut()
      this.$router.push({ name: 'login' })
      this.$root.$emit('Spinner::hide')
    },
    async getUser () {
      await this.$firebase.database().ref(`support/supportAffiliates/${window.uid}`).on('value', snapshot => {
        this.user = snapshot.val()
      })
      this.$root.$emit('verifyActived')
    },
    verifyActived () {
      if (this.user.actived === false) {
        this.doLogout()
        Vue.prototype.$actived = false
      }
      Vue.prototype.$actived = true
    }
  }
}
</script>

<style lang="scss" scoped>
.col-lg-2{
  padding: 0;
  margin: 0;
}
.left-icon{
  position: absolute;
  transform: rotateX(180deg) rotateY(180deg) rotate(270deg) translate(3px, 27px);
}
.dropdown-box{
  margin: 0 2px 0px 28px;
  padding-bottom: 15px;
  background-color: #fafafa;
  border-radius: 5px;
  font-size: 13.7px !important;
  font-weight: 400 !important;
  // &:not(.actived) li a{
  //   transform: translate(-200px, 0) !important;
  //   transition: all .3s !important;
  // }
  &.actived li a{
    transform: translate(21px, 0) !important;
    transition: all .3s !important;
    border-left: 1px solid #e1e1e1;
    border-radius: 0px !important;
    padding-left: 32px !important;
  }
}
.navigation-sidebar{
  height: 100%;
  overflow: hidden auto;
  width: 240px;
  box-shadow: none;
  background-color: white;
  z-index: 0;
  border-right: 2px solid #E2E8F0;

  &::-webkit-scrollbar {
    display: none !important;
  }
  .hub-logo {
    width: 130px;
    height: 30px;
    min-height: min-content;
    margin: 24px auto;
    margin-bottom: 24px !important;

    &.adjust-img {
      animation: adjustImg 0.6s forwards;
    }

    &.reverse-adjust {
      animation: ReverseImg 0.6s forwards;
    }
  }
  .hub-icon {
    width: 20px;
    height: 30px;
    min-height: min-content;
    justify-content: center;
    margin: 24px auto;
    margin-bottom: 24px !important;

    &.hide-icon {
      animation: hideIcon 0.6s forwards;
    }

    &.show-icon {
      animation: showIcon 0.6s forwards;
    }
  }
  .divisor-container {
    height: 24px;
    width: 100%;

    .line {
      position: relative;
      width: 100%;
      height: 2px;
      background-color: #E2E8F0;

      .arrow-button {
        cursor: pointer;
        box-sizing: content-box;
        position: absolute;
        bottom: -11px;
        left: 190px;
        width: 24px;
        height: 24px;
        border: 1px solid #E2E8F0;
        border-radius: 50%;
        background-color: white;

        &.shrink-line {
          animation: shrinkLine 0.6s forwards;
        }

        &.expand-line {
          animation: expandLine 0.6s forwards;
        }

        &:hover {
          background-color: #E2E8F0;
        }
      }
    }
  }

  .division-title {
    color: #64748B;
    font-size: 13px;
    font-weight: 500;
    margin-left: 16px;

    &.shrink-division-title {
      animation: shrinkDivisionTitle 0.6s forwards;
    }

    &.expand-division-title {
      animation: expandDivisionTitle 0.6s forwards;
    }
  }

  &.shrink-sidebar {
    animation: shrink 0.6s forwards;
  }

  &.expand-sidebar {
    animation: expand 0.6s forwards;
  }

  .division-line {
    width: 40px;
    height: 2px;
    background-color: #E2E8F0;
    margin: 8px 0px 25.5px 21px;
  }
  .icon-ajust{
    margin-left: 2px;
    margin-right: 10px;
  }
  li{
    margin: 3.5px 5px;
    letter-spacing: .25px;
  }
}
.img-logo {
  width: 153px;
  transform: translate(-28px, 0px);
}
.user-info {
  display: flex;
  justify-content: space-between;
  background: #F1F5F9;
  padding: 10px;
  border-radius: 8px;
  margin: 0 16px;
  margin-bottom: 16px;
  width: 188px;
  height: 58px;

  .names-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .btn-logout-profile {
    background-color: white;
    border: none;
    padding: 0px 4px !important;
    position: absolute;
    transform: translate(18px, 22px);
    height: 24px !important;
    i {
      font-size: 13px !important;
      margin: 0;
      transform: translate(0, 1px);
      color: var(--red-light)
    }
  }

  .photo {
    height: 32px;
    width: 32px;
    border-radius: 6.4px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .notification {
    height: 32px;
    width: 32px;
    border-radius: 6.4px;
    border: solid 1px #CBD5E1;
    transform: translate(0px, 4px);
    padding: 2px 3px !important;
    cursor: pointer;
    // margin-right: 8px;

    &.hiding-notification {
      animation: hidingNotification 0.6s forwards;
    }

    &.show-notification {
      animation: showNotification 0.6s forwards;
    }

    &:hover {
      background-color: #E2E8F0;
    }
  }

  .name-user {
    font-size: 13px;
    font-weight: 600;
    margin: 0px;
    color: #475569;

    &.hiding-text {
      animation: hidingText 0.6s forwards;
    }

    &.show-text {
      animation: showText 0.6s forwards;
    }
  }

  .emissions-user {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 0px;
    color: #64748B;

    &.hiding-text {
      animation: hidingText 0.6s forwards;
    }

    &.show-text {
      animation: showText 0.6s forwards;
    }
  }

  .name-loading {
    background-color: #dbdbdb !important;
    height: 19px;
    width: 105px;
    border-radius: 3px;
    margin: 3px 0;
  }

  .emissions-loading {
    background-color: #dbdbdb !important;
    height: 18px;
    width: 100px;
    border-radius: 3px;
    margin: 3px 0;
  }

  .icon-user {
    background-color: var(--featured) !important;
    background: #ffc0c0;
    height: 45px;
    width: 45px;
    border-radius: 7px;
    text-align: center;
    margin-right: 10px;

    i {
      color: white;
      height: 45px;
      width: 45px !important;
      vertical-align: middle;
      line-height: 40px;
    }
  }

  .icon-loading {
    background-color: var(--featured) !important;
    background: #ffc0c0;
    height: 45px;
    width: 45px;
    border-radius: 7px;
    text-align: center;
    margin-right: 10px;

    i {
      margin: 0 !important;
      color: white;
      height: 45px !important;
      width: 45px !important;
      vertical-align: middle;
      line-height: 45px;
      font-size: 15px
    }
  }

  &.shrink-container {
    animation: shrinkContainer 0.6s forwards;
  }

  &.expand-container {
    animation: expandContainer 0.6s forwards;
  }
}
.questionsIcon {
  width: 50px;
  height: 50px;
}
// Componente 'navigation-sidebar'
@keyframes shrink {
  from {
    width: 240px;
  }

  to {
    width: 84px;
  }
}

@keyframes expand {
  from {
    width: 84px;
  }

  to {
    width: 240px;
  }
}

// Logo simpleshub
@keyframes adjustImg {
  0% {
    margin-left: 45px;
    opacity: 1;
  }

  50% {
    margin-left: 40px;
    opacity: 0.5;
  }

  100% {
    margin-left: 30px;
    opacity: 0;
  }
}

@keyframes ReverseImg {
  0% {
    margin-left: 30px;
    opacity: 0;
  }

  50% {
    margin-left: 40px;
    opacity: 0.5;
  }

  100% {
    margin-left: 45px;
    opacity: 1;
  }
}
// Componente 'divisor-container'
@keyframes shrinkLine {
  from {
    transform: none;
    left: 190px;
  }

  to {
    left: 48px;
    transform: rotate(-180deg);
  }
}

// Componente 'division-title'
@keyframes shrinkDivisionTitle {
  from {
    margin-left: 16px;
  }

  to {
    margin-left: 20px;
  }
}

@keyframes expandDivisionTitle {
  from {
    margin-left: 20px;
  }

  to {
    margin-left: 16px;
  }
}

@keyframes expandLine {
  from {
    left: 48px;
  }

  to {
    left: 190px;
  }
}
// Ícone simpleshub
@keyframes hideIcon {
  0% {
    // margin-left: 45px;
    opacity: 1;
  }

  50% {
    // margin-left: 40px;
    opacity: 0.5;
  }

  100% {
    // margin-left: 30px;
    opacity: 0;
    }
    }
    .name-user{
      font-size: 11px;
      font-weight: 700;
      margin: 4px 0;
  }
    .name-user{
      font-size: 11px;
      font-weight: 700;
      margin: 4px 0;
}

@keyframes showIcon {
  0% {
    // margin-left: 30px;
    opacity: 0;
  }

  50% {
    // margin-left: 40px;
    opacity: 0.5;
  }

  100% {
    // margin-left: 45px;
    opacity: 1;
}
}
.questionsIcon {
  width: 50px;
  height: 50px;
  }
.questionsIcon {
  width: 50px;
  height: 50px;
}
@keyframes shrinkContainer {
  from {
    width: 208px;
  }

  to {
    width: 52px;

  }
}

@keyframes expandContainer {
  from {
    // margin-right: 16px;
    width: 52px;
  }

  to {
    width: 208px;
    // margin-right: 11px;
  }
}
// Várias usos
@keyframes hidingText {
  0% {
    display: none;
  }

  50% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes showText {
  0% {
    opacity: 0;
    display: none;
  }

  25% {
    opacity: 0;
    display: none;
  }

  50% {
    opacity: 0;
    display: none;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}
</style>
