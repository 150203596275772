<template>
  <div class="icon-container" :style="iconStyle">
    <svg v-if="icon === 'server'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0029 6.99792V18.9783C19.0029 20.0969 18.0961 21.0038 16.9775 21.0038H7.02251C5.9039 21.0038 4.99708 20.0969 4.99708 18.9783V6.99792"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.99833 10.9996H16.0017" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M5.99933 7.99834H18.0007C18.3798 7.99834 18.7263 7.78404 18.8957 7.44487C19.0651 7.1057 19.0282 6.69993 18.8004 6.39687L16.8449 3.79499C16.467 3.29211 15.8745 2.99625 15.2455 2.99625H8.75837C8.12996 2.99625 7.53806 3.29149 7.16004 3.79348L5.20017 6.39612C4.97201 6.6991 4.93481 7.10505 5.1041 7.44445C5.27338 7.78385 5.62004 7.99834 5.99933 7.99834Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.99833 14.0008H16.0017" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M7.99833 17.6023H10.9996" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M15.5515 17.5523C15.5515 17.5799 15.5291 17.6023 15.5015 17.6023C15.4738 17.6023 15.4514 17.5799 15.4514 17.5523C15.4514 17.5247 15.4738 17.5023 15.5015 17.5023"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.5015 17.5023C15.5291 17.5023 15.5515 17.5247 15.5515 17.5523" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'protected-sync'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.583 4.406C20.243 5.065 20.243 6.134 19.583 6.793C18.923 7.452 17.854 7.452 17.194 6.793C16.534 6.134 16.534 5.065 17.194 4.406C17.854 3.747 18.923 3.747 19.583 4.406"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M4.43097 19.594C3.77097 18.935 3.77097 17.866 4.43097 17.207C5.09097 16.548 6.15997 16.548 6.81997 17.207C7.47997 17.866 7.47997 18.935 6.81997 19.594C6.15997 20.253 5.08997 20.253 4.43097 19.594"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.94098 19.448C10.447 21.83 15.259 21.472 18.368 18.366C20.127 16.608 21.007 14.304 21.007 12C21.007 11.468 20.975 10.937 20.881 10.412"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M3.13299 13.588C3.03899 13.063 3.00699 12.532 3.00699 12C3.00699 9.696 3.88699 7.392 5.64599 5.634C8.75399 2.528 13.567 2.17 17.073 4.552"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 15.75C12 15.75 15 14.311 15 12.153V9.67899L12.812 8.89199C12.286 8.70299 11.712 8.70299 11.186 8.89199L9 9.67899V12.153C9 14.311 12 15.75 12 15.75Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'server-sync'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M16 20L19 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 20H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 17V20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 5L5 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 5H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 8V5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.56251 18.5V21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M9.56251 21L5.56251 20.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <rect x="3" y="12" width="9" height="6.5" rx="1.19696" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.5 6H15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.5 9H15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 9V5C12 3.89543 12.8954 3 14 3H19C20.1046 3 21 3.89543 21 5V11C21 12.1046 20.1046 13 19 13H16"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'office'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M3.99663 3.99667H14.0008C14.5533 3.99667 15.0012 4.44458 15.0012 4.99709V20.0033H3.99663C3.44412 20.0033 2.99622 19.5554 2.99622 19.0029V4.99709C2.99622 4.44458 3.44412 3.99667 3.99663 3.99667Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M15.0012 7.99832H20.0033C20.5558 7.99832 21.0037 8.44622 21.0037 8.99874V19.0029C21.0037 19.5554 20.5558 20.0033 20.0033 20.0033H15.0012V7.99832Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.99789 7.87326C6.92882 7.87326 6.87284 7.92925 6.87284 7.99832C6.87284 8.06738 6.92882 8.12337 6.99789 8.12337C7.06695 8.12337 7.12294 8.06738 7.12294 7.99832C7.12294 7.92925 7.06695 7.87326 6.99789 7.87326"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.9996 7.87326C10.9305 7.87326 10.8745 7.92925 10.8745 7.99832C10.8745 8.06738 10.9305 8.12337 10.9996 8.12337C11.0687 8.12337 11.1247 8.06738 11.1247 7.99832C11.1247 7.92925 11.0687 7.87326 10.9996 7.87326"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.99789 11.8749C6.92882 11.8749 6.87284 11.9309 6.87284 12C6.87284 12.0691 6.92882 12.125 6.99789 12.125C7.06695 12.125 7.12294 12.0691 7.12294 12C7.12294 11.9309 7.06695 11.8749 6.99789 11.8749"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.9996 11.8749C10.9305 11.8749 10.8745 11.9309 10.8745 12C10.8745 12.0691 10.9305 12.125 10.9996 12.125C11.0687 12.125 11.1247 12.0691 11.1247 12C11.1247 11.9309 11.0687 11.8749 10.9996 11.8749"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.99789 15.8766C6.92882 15.8766 6.87284 15.9326 6.87284 16.0017C6.87284 16.0707 6.92882 16.1267 6.99789 16.1267C7.06695 16.1267 7.12294 16.0707 7.12294 16.0017C7.12294 15.9326 7.06695 15.8766 6.99789 15.8766"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.9996 15.8766C10.9305 15.8766 10.8745 15.9326 10.8745 16.0017C10.8745 16.0707 10.9305 16.1267 10.9996 16.1267C11.0687 16.1267 11.1247 16.0707 11.1247 16.0017C11.1247 15.9326 11.0687 15.8766 10.9996 15.8766"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M18.0025 11.8749C17.9334 11.8749 17.8774 11.9309 17.8774 12C17.8774 12.0691 17.9334 12.125 18.0025 12.125C18.0715 12.125 18.1275 12.0691 18.1275 12C18.1275 11.9309 18.0715 11.8749 18.0025 11.8749"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M18.0025 15.8766C17.9334 15.8766 17.8774 15.9326 17.8774 16.0017C17.8774 16.0707 17.9334 16.1267 18.0025 16.1267C18.0715 16.1267 18.1275 16.0707 18.1275 16.0017C18.1275 15.9326 18.0715 15.8766 18.0025 15.8766"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else-if="icon === 'home'" width="24" height="24" viewBox="0 0 30 30" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6 15H8.775L12.027 22.5L17.973 7.5L21.225 15H24" stroke="currentColor" stroke-width="3"
        stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M23.6842 29.25H6.31579C3.24211 29.25 0.75 26.7579 0.75 23.6842V6.31579C0.75 3.24211 3.24211 0.75 6.31579 0.75H23.6842C26.7579 0.75 29.25 3.24211 29.25 6.31579V23.6842C29.25 26.7579 26.7579 29.25 23.6842 29.25Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else-if="icon === 'taskHub'" width="22" height="22" viewBox="0 0 30 30" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6 1.5H24C25.0526 1.5 26.0208 1.86142 26.7873 2.46693C27.2249 2.13925 27.7098 1.89436 28.2182 1.73302C27.1344 0.661546 25.6444 0 24 0H6C2.68629 0 0 2.68629 0 6V24C0 27.3137 2.68629 30 6 30H24C27.3137 30 30 27.3137 30 24V12.451L28.5 13.981V24C28.5 26.4853 26.4853 28.5 24 28.5H6C3.51472 28.5 1.5 26.4853 1.5 24V6C1.5 3.51472 3.51472 1.5 6 1.5Z"
        fill="currentColor" />
      <path d="M7.5 17.5L11.0455 21.1262C11.782 21.8795 12.9942 21.8783 13.7293 21.1237L27 7.5" stroke="currentColor"
        stroke-width="4.5" stroke-linecap="round" />
    </svg>
    <svg v-else-if="icon === 'monitor'" width="22" height="22" viewBox="0 0 30 30" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1318_686)">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M6 1.5H24C26.4853 1.5 28.5 3.51472 28.5 6V20.545L30 22.045V6C30 2.68629 27.3137 0 24 0H6C2.68629 0 0 2.68629 0 6V24C0 27.3137 2.68629 30 6 30H22.0451L20.5451 28.5H6C3.51472 28.5 1.5 26.4853 1.5 24V6C1.5 3.51472 3.51472 1.5 6 1.5Z"
          fill="currentColor" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M21.4616 18.8099C20.8163 19.902 19.902 20.8163 18.8099 21.4616L25.8617 28.5133C26.5939 29.2456 27.7811 29.2456 28.5133 28.5133C29.2456 27.7811 29.2456 26.5939 28.5133 25.8617L21.4616 18.8099Z"
          fill="currentColor" />
        <circle cx="15" cy="15" r="6.75" stroke="currentColor" stroke-width="4.5" />
      </g>
      <defs>
        <clipPath id="clip0_1318_686">
          <rect width="30" height="30" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
    <svg v-else-if="icon === 'connectHub'" width="22" height="22" viewBox="0 0 30 30" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M24 1.5H6C3.51472 1.5 1.5 3.51472 1.5 6V24C1.5 26.4853 3.51472 28.5 6 28.5H24C26.4853 28.5 28.5 26.4853 28.5 24V6C28.5 3.51472 26.4853 1.5 24 1.5ZM6 0C2.68629 0 0 2.68629 0 6V24C0 27.3137 2.68629 30 6 30H24C27.3137 30 30 27.3137 30 24V6C30 2.68629 27.3137 0 24 0H6Z"
        fill="currentColor" />
      <path
        d="M25.4861 6.20563C25.6191 5.22147 24.7785 4.38091 23.7944 4.5139L5.80349 6.94511C4.35104 7.14138 4.00875 9.08755 5.30708 9.76762L13.3086 13.9589L22.1335 7.86657L16.0412 16.6915L20.2324 24.6929C20.9125 25.9912 22.8586 25.649 23.0549 24.1965L25.4861 6.20563Z"
        fill="currentColor" />
    </svg>
    <svg v-else-if="icon === 'xmlHub'" width="22" height="22" viewBox="0 0 30 30" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1318_703)">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.5 24L1.5 6C1.5 3.51472 3.51472 1.5 6 1.5L24 1.5C26.4853 1.5 28.5 3.51472 28.5 6L28.5 24C28.5 25.3327 27.9206 26.5302 27 27.3542L27 29.1973C28.7934 28.1599 30 26.2208 30 24L30 6C30 2.68629 27.3137 -1.19392e-06 24 -1.04907e-06L6 -2.62268e-07C2.68629 -1.17422e-07 -1.19392e-06 2.68629 -1.04907e-06 6L-2.62268e-07 24C-1.17422e-07 27.3137 2.68629 30 6 30L6 28.5C3.51472 28.5 1.5 26.4853 1.5 24Z"
          fill="currentColor" />
        <path
          d="M9 15.375C9 14.3395 9.83947 13.5 10.875 13.5L11.625 13.5C12.6605 13.5 13.5 14.3395 13.5 15.375L13.5 28.125C13.5 29.1605 12.6605 30 11.625 30H10.875C9.83947 30 9 29.1605 9 28.125L9 15.375Z"
          fill="currentColor" />
        <rect x="15" y="9" width="4.5" height="21" rx="1.875" fill="currentColor" />
        <path
          d="M21 6.37502C21 5.33949 21.8395 4.50002 22.875 4.50002L23.625 4.50002C24.6605 4.50002 25.5 5.33949 25.5 6.37502L25.5 28.125C25.5 29.1605 24.6605 30 23.625 30L22.875 30C21.8395 30 21 29.1605 21 28.125L21 6.37502Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1318_703">
          <rect y="30" width="30" height="30" rx="6" transform="rotate(-90 0 30)" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
    <svg v-else-if="icon === 'subscriptions'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.31303 7.75894C8.46494 7.91085 8.46494 8.15715 8.31303 8.30907C8.16111 8.46098 7.91481 8.46098 7.7629 8.30907C7.61099 8.15715 7.61099 7.91085 7.7629 7.75894C7.91481 7.60703 8.16111 7.60703 8.31303 7.75894"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M7.244 3.025L10.093 3C10.624 2.995 11.135 3.205 11.511 3.58L20.415 12.488C21.196 13.269 21.196 14.536 20.415 15.317L15.321 20.414C14.54 21.196 13.272 21.196 12.491 20.414L3.58 11.5C3.209 11.129 3 10.625 3 10.099V7.287C3 6.761 3.209 6.257 3.58 5.886L5.86 3.605C6.228 3.237 6.725 3.029 7.244 3.025Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else-if="icon === 'help-center'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12 13.25V13C12 12.183 12.505 11.74 13.011 11.4C13.505 11.067 14 10.633 14 9.83301C14 8.72801 13.105 7.83301 12 7.83301C10.895 7.83301 10 8.72801 10 9.83301"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M11.999 16C11.861 16 11.749 16.112 11.75 16.25C11.75 16.388 11.862 16.5 12 16.5C12.138 16.5 12.25 16.388 12.25 16.25C12.25 16.112 12.138 16 11.999 16"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else-if="icon === 'help'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12 13.25V13C12 12.183 12.505 11.74 13.011 11.4C13.505 11.067 14 10.633 14 9.83301C14 8.72801 13.105 7.83301 12 7.83301C10.895 7.83301 10 8.72801 10 9.83301"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M11.999 16C11.861 16 11.749 16.112 11.75 16.25C11.75 16.388 11.862 16.5 12 16.5C12.138 16.5 12.25 16.388 12.25 16.25C12.25 16.112 12.138 16 11.999 16"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else-if="icon === 'logout'" width="22" height="22" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M11 18.998V7.50301C11 6.81301 10.645 6.17201 10.06 5.80701L6.06 3.30701C4.728 2.47501 3 3.43201 3 5.00301V16.497C3 17.187 3.355 17.828 3.94 18.193L7.94 20.693C9.272 21.526 11 20.568 11 18.998Z"
        stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21 11H15" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17 9L15 11L17 13" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 19H15C16.105 19 17 18.105 17 17V16" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M17 6V5C17 3.895 16.105 3 15 3H5" stroke="#EF4444" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'client'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M15 21V4C15 3.448 14.552 3 14 3H6C5.448 3 5 3.448 5 4V21" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20 21V10C20 9.448 19.552 9 19 9H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M7.5 17H12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.5 17V21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.5 21V17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 10.5H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 13.5H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 7.5H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 13.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 17H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21 21H4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else-if="icon === 'plus'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5Z"
        fill="currentColor" />
    </svg>
    <svg v-else-if="icon === 'drag'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
        fill="currentColor" />
    </svg>
    <svg v-else-if="icon === 'edit'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M5.293 15.619L15.619 5.29299C16.009 4.90299 16.642 4.90299 17.032 5.29299L18.708 6.96899C19.098 7.35899 19.098 7.99199 18.708 8.38199L8.381 18.707C8.194 18.895 7.94 19 7.675 19H5V16.325C5 16.06 5.105 15.806 5.293 15.619Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.75 7.16016L16.84 10.2502" stroke="" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-else-if="icon === 'trash'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M16.1379 21H7.85794C6.81094 21 5.94094 20.192 5.86294 19.147L4.96594 7H18.9999L18.1329 19.142C18.0579 20.189 17.1869 21 16.1379 21V21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 11V17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4 7H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17 7L15.987 4.298C15.694 3.517 14.948 3 14.114 3H9.886C9.052 3 8.306 3.517 8.013 4.298L7 7"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.43 11L15 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M8.57003 11L9.00003 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'chevron-right'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M10 16L14 12L10 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'x'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M8 8L16 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 8L8 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'info'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 17V12H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M11.749 8C11.611 8 11.499 8.112 11.5 8.25C11.5 8.388 11.612 8.5 11.75 8.5C11.888 8.5 12 8.388 12 8.25C12 8.112 11.888 8 11.749 8"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'check'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M20 6.5L9 17.5L4 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'check-circle'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 10L11 15L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'legal'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <rect x="2.99622" y="2.99625" width="18.0075" height="18.0075" rx="4" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M9.07278 9.1058L7.58616 10.2913C7.25094 10.559 7.04536 10.9567 7.02074 11.385C6.99612 11.8133 7.15477 12.2318 7.45711 12.5362L8.43651 13.5206C8.73891 13.8248 9.15655 13.9858 9.58487 13.9632C10.0132 13.9407 10.4116 13.7368 10.6804 13.4026L11.8729 11.921C12.1828 11.5359 12.5341 11.186 12.9204 10.8775L14.407 9.69204C14.7425 9.4245 14.9483 9.02681 14.9729 8.5984C14.9975 8.16999 14.8387 7.75134 14.5361 7.44711L13.5566 6.4627C13.2542 6.15853 12.8366 5.99756 12.4083 6.0201C11.98 6.04263 11.5815 6.24653 11.3127 6.58075L10.1212 8.06236C9.81123 8.44772 9.45962 8.79766 9.07278 9.1058V9.1058Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6494 17.0021H6.99792" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M13.3356 10.5464L17.4163 14.0008C17.902 14.4135 18.1183 15.0618 17.9775 15.6835C17.8367 16.3051 17.3622 16.797 16.746 16.9601C16.1299 17.1232 15.4741 16.9304 15.0443 16.4599L11.4268 12.4742"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'ellipsis'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M18.5027 12C18.5027 12.2763 18.2788 12.5002 18.0025 12.5002C17.7263 12.5002 17.5023 12.2763 17.5023 12C17.5023 11.7237 17.7263 11.4998 18.0025 11.4998C18.2788 11.4998 18.5027 11.7237 18.5027 12"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12.5002 12C12.5002 12.2763 12.2762 12.5002 12 12.5002C11.7237 12.5002 11.4998 12.2763 11.4998 12C11.4998 11.7237 11.7237 11.4998 12 11.4998C12.2762 11.4998 12.5002 11.7237 12.5002 12"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.49773 12C6.49773 12.2763 6.27378 12.5002 5.99752 12.5002C5.72127 12.5002 5.49731 12.2763 5.49731 12C5.49731 11.7237 5.72127 11.4998 5.99752 11.4998C6.27378 11.4998 6.49773 11.7237 6.49773 12"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'settings'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12.8004 2.99625C13.2225 2.99625 13.5991 3.26128 13.7418 3.65853L14.25 5.07412C14.3307 5.29673 14.4872 5.48376 14.6922 5.60234L16.1998 6.4727C16.4045 6.59082 16.6445 6.63264 16.8771 6.59075L18.3577 6.32364C18.7735 6.24804 19.1919 6.44184 19.4031 6.80784L20.2035 8.18842C20.4153 8.55342 20.3752 9.01209 20.1034 9.33489L19.132 10.4834C18.979 10.664 18.895 10.893 18.8949 11.1296V12.8704C18.895 13.1071 18.979 13.336 19.132 13.5166L20.1034 14.6651C20.3752 14.9879 20.4153 15.4466 20.2035 15.8116L19.4031 17.1922C19.192 17.5578 18.7741 17.7515 18.3587 17.6764L16.8781 17.4093C16.6455 17.3674 16.4055 17.4092 16.2008 17.5273L14.6932 18.3977C14.4882 18.5162 14.3317 18.7033 14.251 18.9259L13.7428 20.3415C13.6 20.7391 13.2228 21.0041 12.8004 21.0038H11.1997C10.7776 21.0038 10.401 20.7387 10.2583 20.3415L9.75011 18.9259C9.66947 18.7036 9.51333 18.5166 9.30893 18.3977L7.8003 17.5273C7.59557 17.4092 7.35563 17.3674 7.12302 17.4093L5.6424 17.6764C5.22663 17.752 4.8082 17.5582 4.59696 17.1922L3.79663 15.8116C3.58483 15.4466 3.62485 14.9879 3.89667 14.6651L4.86808 13.5166C5.02107 13.336 5.10508 13.1071 5.10518 12.8704V11.1296C5.10508 10.893 5.02107 10.664 4.86808 10.4834L3.90668 9.33489C3.63486 9.01209 3.59483 8.55342 3.80664 8.18842L4.60697 6.80784C4.81807 6.44223 5.23597 6.2485 5.6514 6.32364L7.13202 6.59075C7.36464 6.63264 7.60458 6.59082 7.8093 6.4727L9.31793 5.60234C9.52233 5.48338 9.67847 5.29644 9.75911 5.07412L10.2673 3.65853C10.4089 3.26437 10.7809 3.00009 11.1997 2.99625H12.8004Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <circle cx="12" cy="12" r="2.75115" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'document'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'document-plus'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
      >
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M5 21H14C15.105 21 16 20.105 16 19V8.28601C16 7.18101 15.105 6.28601 14 6.28601H5C3.895 6.28601 3 7.18101 3 8.28601V19C3 20.105 3.895 21 5 21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.5 13.64H11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M9.5 11.64V15.64" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M8 6.286V5C8 3.895 8.895 3 10 3H19C20.105 3 21 3.895 21 5V16C21 17.105 20.105 18 19 18H16"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'upload'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6.324 9.068C3.885 9.401 2 11.47 2 14C2 16.761 4.239 19 7 19H18C20.209 19 22 17.209 22 15C22 12.791 20.209 11 18 11C18 7.686 15.314 5 12 5C9.363 5 7.129 6.703 6.324 9.068Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.83301 12.667L12 10.5L14.167 12.667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M12 15.5V10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'document-check'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M14.0017 12L11.5006 14.501L10 13.0004" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'exclamation-circle'" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
      viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 12.5V7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M11.999 16C11.861 16 11.749 16.112 11.75 16.25C11.75 16.388 11.862 16.5 12 16.5C12.138 16.5 12.25 16.388 12.25 16.25C12.25 16.112 12.138 16 11.999 16"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'send'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.75401 12.149L10.525 20.118C10.746 21.111 12.065 21.325 12.588 20.453L20.837 6.704C21.288 5.954 20.748 5 19.873 5H4.32201C3.31901 5 2.81701 6.212 3.52601 6.921L8.75401 12.149Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20.84 5.56L8.75 12.15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'chevron-down'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'chevron-up'" style="transform: rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="24"
      height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'chevron-left'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M15.354 5.64592C15.4005 5.69236 15.4375 5.74754 15.4627 5.80828C15.4879 5.86903 15.5009 5.93415 15.5009 5.99992C15.5009 6.06568 15.4879 6.13081 15.4627 6.19155C15.4375 6.2523 15.4005 6.30747 15.354 6.35392L9.70698 11.9999L15.354 17.6459C15.4479 17.7398 15.5006 17.8671 15.5006 17.9999C15.5006 18.1327 15.4479 18.26 15.354 18.3539C15.2601 18.4478 15.1328 18.5005 15 18.5005C14.8672 18.5005 14.7399 18.4478 14.646 18.3539L8.64598 12.3539C8.59941 12.3075 8.56247 12.2523 8.53727 12.1916C8.51206 12.1308 8.49908 12.0657 8.49908 11.9999C8.49908 11.9341 8.51206 11.869 8.53727 11.8083C8.56247 11.7475 8.59941 11.6924 8.64598 11.6459L14.646 5.64592C14.6924 5.59935 14.7476 5.56241 14.8083 5.5372C14.8691 5.512 14.9342 5.49902 15 5.49902C15.0657 5.49902 15.1309 5.512 15.1916 5.5372C15.2524 5.56241 15.3075 5.59935 15.354 5.64592V5.64592Z"
        fill="currentColor" />
    </svg>
    <svg v-if="icon === 'arrow-up'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M21 12V12C21 16.971 16.971 21 12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 8V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 11L12 8L15 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'arrow-down'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21V21C7.029 21 3 16.971 3 12Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 16V8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 13L12 16L9 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'arrow-left'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 12H5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14 17L19 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14 7L19 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'task'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M19.561 6.74304L12.005 14.299L8.228 10.521" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M20.595 13.506V18.4C20.595 19.56 19.655 20.5 18.495 20.5H5.695C4.535 20.5 3.595 19.56 3.595 18.4V5.6C3.595 4.44 4.535 3.5 5.695 3.5H12.095"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'calendar'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M7.49813 2.99609V5.99734" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M16.5019 2.99609V5.99734" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M2.99625 9.49923H21.0037" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <rect x="2.99625" y="4.49707" width="18.0075" height="16.5069" rx="3" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'calendar-plus'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M15 3V5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 3V5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3 8H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 12.417V6C19 4.895 18.105 4 17 4H5C3.895 4 3 4.895 3 6V17C3 18.105 3.895 19 5 19H12.417"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.999 10.75C6.861 10.75 6.749 10.862 6.75 11C6.75 11.138 6.862 11.25 7 11.25C7.138 11.25 7.25 11.138 7.25 11C7.25 10.862 7.138 10.75 6.999 10.75"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.999 14.75C6.861 14.75 6.749 14.862 6.75 15C6.75 15.138 6.862 15.25 7 15.25C7.138 15.25 7.25 15.138 7.25 15C7.25 14.862 7.138 14.75 6.999 14.75"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.75 11.001C10.75 11.139 10.862 11.251 11 11.25C11.138 11.25 11.25 11.138 11.25 11C11.25 10.862 11.138 10.75 11 10.75C10.862 10.75 10.75 10.862 10.75 11.001"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M17 22C14.239 22 12 19.762 12 17C12 14.296 14.3 11.997 17.004 12C19.764 12.002 22 14.24 22 17C22 19.761 19.762 22 17 22"
        stroke="currentColor" stroke-width="1.5" />
      <path d="M17 15V19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 17H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'user'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M5 19.9996C5 17.4996 7 15.5996 9.4 15.5996H14.5C17 15.5996 18.9 17.5996 18.9 19.9996"
        stroke="currentColor" stroke-width="1.4824" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15 5.2C16.7 6.9 16.7 9.6 15 11.2C13.3 12.8 10.6 12.9 8.99999 11.2C7.39999 9.5 7.29999 6.8 8.99999 5.2C10.7 3.6 13.3 3.6 15 5.2"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'tag'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6.49001 6.5382L3.41901 10.8382C2.92201 11.5332 2.92201 12.4682 3.41901 13.1632L6.49001 17.4632C6.86501 17.9882 7.47101 18.3002 8.11701 18.3002H19C20.105 18.3002 21 17.4052 21 16.3002V7.7002C21 6.5952 20.105 5.7002 19 5.7002H8.11701C7.47101 5.7002 6.86501 6.0122 6.49001 6.5382Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.5728 10.7274C11.2757 11.4303 11.2757 12.57 10.5728 13.273C9.86984 13.9759 8.73015 13.9759 8.02721 13.273C7.32426 12.57 7.32426 11.4303 8.02721 10.7274C8.73015 10.0245 9.86985 10.0245 10.5728 10.7274"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'bullseye'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M14.0408 9.95915L11.5298 12.4702" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M14.0009 5.9975V9.99917H18.0025L22.0042 5.9975L19.3361 4.66395L18.0025 1.99583L14.0009 5.9975Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.9996 3.97165C6.43609 4.25174 2.9064 8.08253 2.99999 12.6537C3.09357 17.2248 6.77709 20.9079 11.3482 21.001C15.9194 21.0941 19.7498 17.5639 20.0294 13.0004"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M16.2117 13.0004C15.9427 15.4635 13.8174 17.3009 11.3413 17.2111C8.86519 17.1213 6.87862 15.1347 6.7888 12.6586C6.69899 10.1825 8.53641 8.05721 10.9995 7.78824"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'eye'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.3639C14.8493 21.8787 9.1508 21.8787 5.6361 18.3639C2.12138 14.8492 2.12138 9.15074 5.6361 5.63604C9.15082 2.12132 14.8493 2.12132 18.364 5.63604"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6.53601 13.073C6.10901 12.427 6.10901 11.573 6.53601 10.927C7.85601 8.93201 9.92801 7.33301 12 7.33301C14.072 7.33301 16.144 8.93201 17.464 10.926C17.892 11.572 17.892 12.427 17.464 13.072C16.143 15.067 14.072 16.665 12 16.665C9.92801 16.667 7.85601 15.068 6.53601 13.073V13.073Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M13.2374 10.7626C13.9208 11.446 13.9208 12.5541 13.2374 13.2375C12.554 13.9209 11.4459 13.9209 10.7625 13.2375C10.0791 12.554 10.0791 11.446 10.7625 10.7626C11.4459 10.0792 12.554 10.0792 13.2374 10.7626"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'share'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4 5.6001C21.9 9.1001 21.9 14.8001 18.4 18.3001C14.9 21.8001 9.2 21.8001 5.7 18.3001C2.2 14.8001 2.2 9.1001 5.7 5.6001C9.2 2.1001 14.8 2.1001 18.4 5.6001"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12.3 12.6001C10.1 12.6001 8.2 13.4001 7.2 15.7001V15.0001C7.2 11.6001 9.5 9.50006 12.3 9.50006V8.00006C12.3 7.50006 12.9 7.20006 13.3 7.50006L17 10.6001C17.3 10.8001 17.3 11.3001 17 11.5001L13.3 14.9001C12.9 15.3001 12.3 15.0001 12.3 14.4001V12.6001Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'building'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M7.99835 21.0037V3.99666C7.99835 3.44415 8.44625 2.99625 8.99877 2.99625H15.0013C15.5538 2.99625 16.0017 3.44415 16.0017 3.99666V21.0037"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.0037 21.0038V7.91131C21.0037 7.40686 20.5948 6.99792 20.0904 6.99792H16.0016" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.9983 6.99792H3.9096C3.40515 6.99792 2.99622 7.40686 2.99622 7.91131V21.0038" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.0016 9.99917H18.5027" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M16.0016 13.0004H18.5027" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M16.0016 16.0017H18.5027" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M5.49731 9.99917H7.99836" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M5.49731 13.0004H7.99836" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M5.49731 16.0017H7.99836" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M10.4994 13.0004H13.5006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M10.4994 16.0017H13.5006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M10.4994 9.99917H13.5006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M10.4994 6.99792H13.5006" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M12 19.0029V21.0037" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M22.0042 21.0037H1.99585" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'handle-arrow'" width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7327 15.5448L18.5448 21.5448L24.5448 15.7327" stroke="currentColor" stroke-width="1.65" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'copy'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M21 5V4C21 3.4 20.6 3 20 3H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M21 15V16C21 16.6 20.6 17 20 17H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M7 5V4C7 3.4 7.4 3 8 3H9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M13 3H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21 11V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M14 21H5C3.9 21 3 20.1 3 19V10C3 8.9 3.9 8 5 8H14C15.1 8 16 8.9 16 10V19C16 20.1 15.1 21 14 21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.5 12V17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 14.5H7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'search'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M15.7138 6.8382C18.1647 9.28913 18.1647 13.2629 15.7138 15.7138C13.2629 18.1647 9.28913 18.1647 6.8382 15.7138C4.38727 13.2629 4.38727 9.28913 6.8382 6.8382C9.28913 4.38727 13.2629 4.38727 15.7138 6.8382"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 19L15.71 15.71" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'repeat'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M4.718 15.979C3.658 14.919 3 13.457 3 11.849V11.849C3 8.632 5.632 6 8.849 6L16 6.021"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14 8.02148L16 6.02148L14 4.02148" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M19.282 8.02148C20.342 9.08148 21 10.5435 21 12.1515V12.1515C21 15.3685 18.368 18.0005 15.151 18.0005L8 17.9795"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 15.9785L8 17.9785L10 19.9785" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'documents'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M15.0014 19.4032V10.8627C15.0014 10.4375 14.8323 10.0303 14.5322 9.73018L12.2693 7.46724C11.9691 7.16712 11.562 6.99805 11.1368 6.99805H5.59749C4.71312 6.99805 3.99683 7.71435 3.99683 8.59871V19.4032C3.99683 20.2876 4.71312 21.0039 5.59749 21.0039H13.4007C14.2851 21.0039 15.0014 20.2876 15.0014 19.4032Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15.0015 17.0019H18.4029C19.2873 17.0019 20.0036 16.2856 20.0036 15.4013V6.8607C20.0036 6.43553 19.8345 6.02836 19.5344 5.72823L17.2715 3.46529C16.9713 3.16516 16.5642 2.99609 16.139 2.99609H10.5997C9.71532 2.99609 8.99902 3.71239 8.99902 4.59676V6.99776"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20.0036 6.99776H16.8023C16.3601 6.99776 16.002 6.63961 16.002 6.19743V2.99609" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.0014 10.9997H11.8001C11.3579 10.9997 10.9998 10.6416 10.9998 10.1994V6.99805" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'checkmarks'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M20.2176 13.2143C20.7168 12.2509 21.0039 11.1595 21.0039 9.99901C21.0039 6.1314 17.8686 2.99609 14.001 2.99609C12.8405 2.99609 11.749 3.28321 10.7856 3.78242"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9.99925 21.0039C6.13064 21.0039 2.99634 17.8676 2.99634 14.001C2.99634 10.1344 6.13064 6.99805 9.99925 6.99805C13.8659 6.99805 17.0022 10.1344 17.0022 14.001C17.0022 17.8676 13.8659 21.0039 9.99925 21.0039"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.1115 12.4453L9.22091 16.3359L6.88794 14.001" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'asterisk'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <circle cx="12" cy="12" r="9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M8.55078 10.004L12.0238 12.009V8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M8.55908 14.0098L12.0241 12.0088V15.9998" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M15.4619 10.0244L12.0239 12.0094L15.4549 13.9904" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'calendar-legal'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M7.49813 2.49604V5.49729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M8.99875 18.0025H4.99709C3.89206 18.0025 2.99625 17.1067 2.99625 16.0017V5.9975C2.99625 4.89247 3.89206 3.99667 4.99709 3.99667H17.0021C18.1071 3.99667 19.0029 4.89247 19.0029 5.9975V10.9996"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19.0029 8.99875H2.99625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M13.0004 21.0037H21.0038" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M14.0008 21.0037V17.0021" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M17.0021 21.0037V17.0021" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M20.0033 21.0037V17.0021" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M13.0004 17.0021H21.0038V15.2183L17.0021 13.5807L13.0004 15.2183V17.0021Z" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.501 2.49604V5.49729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'calendar-clock'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M15 3V5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 3V5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3 9H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 10V7C19 5.343 17.657 4 16 4H6C4.343 4 3 5.343 3 7V16C3 17.657 4.343 19 6 19H10" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.393 15.983V17.741L18.775 18.584" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17.5 22C15.015 22 13 19.986 13 17.5C13 15.066 15.07 12.997 17.504 13C19.988 13.002 22 15.016 22 17.5C22 19.985 19.986 22 17.5 22"
        stroke="currentColor" stroke-width="1.5" />
    </svg>

    <svg v-if="icon === 'warning'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M12 13.1199V9.37988" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M11.999 16.125C11.861 16.125 11.749 16.237 11.75 16.375C11.75 16.513 11.862 16.625 12 16.625C12.138 16.625 12.25 16.513 12.25 16.375C12.25 16.237 12.138 16.125 11.999 16.125"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M14.029 4.13919L21.688 17.5432C22.578 19.1012 21.453 21.0402 19.659 21.0402H4.34101C2.54601 21.0402 1.42101 19.1012 2.31201 17.5432L9.97101 4.13919C10.868 2.56819 13.132 2.56819 14.029 4.13919Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'play'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.514 6.284L16.612 11.143C17.259 11.531 17.259 12.47 16.612 12.858L8.514 17.717C7.848 18.116 7 17.636 7 16.859V7.141C7 6.364 7.848 5.884 8.514 6.284Z"
        fill="currentColor" />
    </svg>

    <svg v-if="icon === 'pause'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M15.5 17V17C14.672 17 14 16.44 14 15.75V8.25C14 7.56 14.672 7 15.5 7V7C16.328 7 17 7.56 17 8.25V15.75C17 16.44 16.328 17 15.5 17Z"
        fill="currentColor" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.5 17V17C7.672 17 7 16.44 7 15.75V8.25C7 7.56 7.672 7 8.5 7V7C9.328 7 10 7.56 10 8.25V15.75C10 16.44 9.328 17 8.5 17Z"
        fill="currentColor" />
    </svg>
    <svg v-if="icon === 'download'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M15.5575 11.1106L12 14.6681L8.4425 11.1106" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 3.99667V14.6681" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M20.0033 16.4457C20.0033 18.4105 18.4106 20.0032 16.4458 20.0032H7.55412C5.58938 20.0032 3.99664 18.4105 3.99664 16.4457"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'mail-search'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M19.7962 14.3107C20.9213 15.4358 20.9213 17.2589 19.7962 18.384C18.671 19.5091 16.848 19.5091 15.7229 18.384C14.5978 17.2589 14.5978 15.4358 15.7229 14.3107C16.848 13.1856 18.672 13.1856 19.7962 14.3107"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19.68 10.5869V5.79744" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M11.04 16.3469H5.28C4.2192 16.3469 3.36 15.4877 3.36 14.4269V5.76" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M17.7494 3.86688H5.29056C4.224 3.86688 3.36 4.73088 3.36 5.79744V5.79744C3.36 6.43776 3.67776 7.03584 4.20768 7.39488L9.3504 10.8806C10.6608 11.7686 12.3811 11.7686 13.6915 10.8806L18.8333 7.39584C19.3622 7.0368 19.68 6.43776 19.68 5.7984V5.79744C19.68 4.73088 18.816 3.86688 17.7494 3.86688V3.86688Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.6 20.1888L19.7952 18.384" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'document-user'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.091 8.15901C10.9697 9.03769 10.9697 10.4623 10.091 11.341C9.21233 12.2197 7.78771 12.2197 6.90903 11.341C6.03035 10.4623 6.03035 9.03769 6.90903 8.15901C7.78771 7.28033 9.21233 7.28033 10.091 8.15901" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 18.5V5.541C2 4.414 2.914 3.5 4.041 3.5H20C21.105 3.5 22 4.395 22 5.5V18.5C22 19.605 21.105 20.5 20 20.5H4C2.895 20.5 2 19.605 2 18.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 9.5H19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.4 13.5H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.095 16.5C11.92 16.061 11.65 15.667 11.303 15.346V15.346C10.716 14.802 9.946 14.5 9.146 14.5H7.854C7.054 14.5 6.284 14.802 5.697 15.346V15.346C5.35 15.667 5.08 16.061 4.905 16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'document-search'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M13.4961 11.7159C14.54 12.7598 14.54 14.4522 13.4961 15.4961C12.4522 16.54 10.7598 16.54 9.71592 15.4961C8.67205 14.4522 8.67205 12.7598 9.71592 11.7159C10.7598 10.672 12.4522 10.672 13.4961 11.7159"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 17L13.5 15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'clock'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M13.433 3.06702C17.688 3.78202 20.933 7.47402 20.933 11.932C20.933 16.903 16.904 20.932 11.933 20.932C7.475 20.932 3.783 17.687 3.068 13.432"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.067 10.4331C3.162 9.86807 3.309 9.32307 3.504 8.79907" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.197 4.99805C5.76 5.35905 5.359 5.76005 4.998 6.19705" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.433 3.06701C9.868 3.16201 9.322 3.31001 8.799 3.50501" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.391 7V12.391H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'clock-negative'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M16.4713 16.4706C16.7376 16.2044 17.1542 16.1802 17.4478 16.398L17.5319 16.4706L20.5303 19.469C20.8232 19.7619 20.8232 20.2368 20.5303 20.5297C20.2641 20.796 19.8474 20.8202 19.5538 20.6023L19.4697 20.5297L16.4713 17.5313C16.1784 17.2384 16.1784 16.7635 16.4713 16.4706Z"
        fill="currentColor" />
      <path
        d="M19.4697 16.4697C19.7626 16.1768 20.2374 16.1768 20.5303 16.4697C20.7966 16.7359 20.8208 17.1526 20.603 17.4462L20.5303 17.5303L17.5319 20.5287C17.2391 20.8216 16.7642 20.8216 16.4713 20.5287C16.205 20.2625 16.1808 19.8458 16.3987 19.5522L16.4713 19.4681L19.4697 16.4697Z"
        fill="currentColor" />
      <path
        d="M12.5 6.25C12.8797 6.25 13.1935 6.53215 13.2432 6.89823L13.25 7V12.5C13.25 12.8797 12.9678 13.1935 12.6018 13.2432L12.5 13.25H8C7.58579 13.25 7.25 12.9142 7.25 12.5C7.25 12.1203 7.53215 11.8065 7.89823 11.7568L8 11.75H11.75V7C11.75 6.6203 12.0322 6.30651 12.3982 6.25685L12.5 6.25Z"
        fill="currentColor" />
      <path
        d="M12 2.25C17.3848 2.25 21.75 6.61522 21.7499 12.0127C21.7375 12.7447 21.6411 13.4728 21.4627 14.1828C21.3618 14.5845 20.9543 14.8283 20.5526 14.7274C20.1509 14.6265 19.907 14.219 20.008 13.8172C20.1584 13.2185 20.2396 12.6045 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 11.9872 20.2501C12.6045 20.2396 13.2185 20.1583 13.8172 20.0079C14.219 19.907 14.6264 20.1508 14.7274 20.5525C14.8283 20.9542 14.5845 21.3617 14.1828 21.4627C13.4728 21.6411 12.7447 21.7374 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25Z"
        fill="currentColor" />
    </svg>
    <svg v-if="icon === 'clock-positive'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 12.3643 21.7273 12.729 21.6823 13.0923C21.6313 13.5033 21.2568 13.7953 20.8457 13.7443C20.4347 13.6933 20.1427 13.3188 20.1937 12.9077C20.2312 12.6055 20.25 12.3024 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C12.3024 20.25 12.6055 20.2312 12.9077 20.1937C13.3188 20.1427 13.6933 20.4347 13.7443 20.8457C13.7953 21.2568 13.5033 21.6313 13.0923 21.6823C12.729 21.7273 12.3643 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25Z"
        fill="currentColor" />
      <path
        d="M19.4697 16.4697C19.7626 16.1768 20.2374 16.1768 20.5303 16.4697C20.7966 16.7359 20.8208 17.1526 20.6029 17.4462L20.5303 17.5303L18.0303 20.0303C17.7641 20.2966 17.3474 20.3208 17.0538 20.1029L16.9697 20.0303L15.4697 18.5303C15.1768 18.2374 15.1768 17.7626 15.4697 17.4697C15.7359 17.2034 16.1526 17.1792 16.4462 17.3971L16.5303 17.4697L17.5 18.439L19.4697 16.4697Z"
        fill="currentColor" />
      <path
        d="M12.5 6.25C12.8797 6.25 13.1935 6.53215 13.2432 6.89823L13.25 7V12.5C13.25 12.8797 12.9678 13.1935 12.6018 13.2432L12.5 13.25H8C7.58579 13.25 7.25 12.9142 7.25 12.5C7.25 12.1203 7.53215 11.8065 7.89823 11.7568L8 11.75H11.75V7C11.75 6.6203 12.0322 6.30651 12.3982 6.25685L12.5 6.25Z"
        fill="currentColor" />
    </svg>
    <svg v-if="icon === 'hourglass'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M10 18H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M16.979 3H7.021C6.469 3 6.021 3.448 6.021 4V6.378C6.021 6.799 6.154 7.21 6.401 7.551L9.621 12L6.401 16.449C6.154 16.79 6.021 17.201 6.021 17.622V20C6.021 20.552 6.469 21 7.021 21H16.978C17.53 21 17.978 20.552 17.978 20V17.658C17.978 17.214 17.83 16.783 17.558 16.432L14.121 12L17.559 7.567C17.831 7.216 17.979 6.785 17.979 6.341V4C17.979 3.448 17.531 3 16.979 3Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'processing'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M6.75 8.25H3V4.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M21 12C21 7.029 16.971 3 12 3C8.36999 3 5.24899 5.154 3.82599 8.25" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.25 15.75H21V19.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M3 12C3 16.971 7.029 21 12 21C15.63 21 18.751 18.846 20.174 15.75" stroke="currentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'mail'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M4.636 5.04492H19.363C20.267 5.04492 21 5.77792 21 6.68192V17.3179C21 18.2219 20.267 18.9539 19.364 18.9539H4.636C3.733 18.9549 3 18.2219 3 17.3179V6.68192C3 5.77792 3.733 5.04492 4.636 5.04492Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.11899 6.07504L10.813 11.578C11.508 12.075 12.442 12.076 13.138 11.58L20.876 6.06104"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'user-alert'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M12.4692 3.02278C13.8329 4.38649 13.8329 6.5975 12.4692 7.96121C11.1055 9.32492 8.89448 9.32492 7.53077 7.96121C6.16706 6.5975 6.16706 4.38649 7.53077 3.02278C8.89448 1.65907 11.1055 1.65907 12.4692 3.02278"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M14.363 12.796C13.064 12.277 11.54 11.991 10 11.991C5.952 11.991 2 13.958 2 16.983V17.983C2 18.535 2.448 18.983 3 18.983H12.413"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M17 22C14.239 22 12 19.762 12 17C12 14.296 14.3 11.997 17.004 12C19.764 12.002 22 14.24 22 17C22 19.761 19.762 22 17 22"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17 16.78V14.78" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M17.001 19.25C17.078 19.25 17.14 19.188 17.139 19.111C17.139 19.034 17.077 18.972 17 18.972C16.923 18.972 16.861 19.034 16.861 19.111C16.861 19.188 16.923 19.25 17.001 19.25"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'user-error'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M12.4692 3.02278C13.8329 4.38649 13.8329 6.5975 12.4692 7.96121C11.1055 9.32492 8.89447 9.32492 7.53076 7.96121C6.16705 6.5975 6.16705 4.38649 7.53076 3.02278C8.89447 1.65907 11.1055 1.65907 12.4692 3.02278"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M14.363 12.796C13.064 12.277 11.54 11.991 10 11.991C5.952 11.991 2 13.958 2 16.983V17.983C2 18.535 2.448 18.983 3 18.983H12.413"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M17 22C14.239 22 12 19.762 12 17C12 14.296 14.3 11.997 17.004 12C19.764 12.002 22 14.24 22 17C22 19.761 19.762 22 17 22"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.41 15.59L15.59 18.41" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M18.41 18.41L15.59 15.59" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'whatsapp'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path fill="currentColor"
        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
    </svg>
    <svg v-if="icon === 'arroba'" width="24" height="24" viewBox="0 0 26 26" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 12C16 14.209 14.209 16 12 16C9.791 16 8 14.209 8 12C8 9.791 9.791 8 12 8V8C14.209 8 16 9.791 16 12V13.5C16 14.881 17.119 16 18.5 16C19.881 16 21 14.881 21 13.5V12C21 7.029 16.971 3 12 3C7.029 3 3 7.029 3 12C3 16.971 7.029 21 12 21C13.149 21 14.317 20.782 15.444 20.315C16.052 20.063 16.614 19.747 17.133 19.386"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'headset'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19 17H18C17.448 17 17 16.552 17 16V11C17 10.448 17.448 10 18 10H19C20.105 10 21 10.895 21 12V15C21 16.105 20.105 17 19 17Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 17H5C3.895 17 3 16.105 3 15V12C3 10.895 3.895 10 5 10H6C6.552 10 7 10.448 7 11V16C7 16.552 6.552 17 6 17Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.5 10V9.5C18.5 5.91 15.59 3 12 3V3C8.41 3 5.5 5.91 5.5 9.5V10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.625 21.25H11.375C10.685 21.25 10.125 20.69 10.125 20V20C10.125 19.31 10.685 18.75 11.375 18.75H12.625C13.315 18.75 13.875 19.31 13.875 20V20C13.875 20.69 13.315 21.25 12.625 21.25Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.875 20H16C17.105 20 18 19.105 18 18V17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'coin'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M12 6V7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 18V16.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9 14.255V14.255C9 15.498 10.007 16.505 11.25 16.505H12.893C14.056 16.505 15 15.562 15 14.398V14.398C15 13.432 14.343 12.59 13.406 12.355L10.594 11.65C9.657 11.415 9 10.573 9 9.607V9.607C9 8.443 9.943 7.5 11.107 7.5H12.75C13.993 7.5 15 8.507 15 9.75V9.75"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'coin-remove'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path
        d="M6.21997 5.11C7.78197 3.794 9.79697 3 12 3C16.971 3 21 7.029 21 12C21 13.447 20.65 14.81 20.043 16.021L17.362 14.681"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M17.78 18.89C16.218 20.206 14.203 21 12 21C7.029 21 3 16.97 3 12C3 10.553 3.35 9.19 3.957 7.979L6.638 9.319"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 7V8.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 17V15.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M14.487 10C14.422 9.024 13.617 8.25 12.625 8.25H11.255C10.285 8.25 9.5 9.036 9.5 10.005C9.5 10.81 10.048 11.512 10.828 11.708L13.171 12.296C13.952 12.492 14.499 13.194 14.499 13.999C14.499 14.969 13.713 15.754 12.744 15.754H11.374C10.38 15.754 9.575 14.978 9.511 14"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'coin-upsell'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0004 8.99875C13.0004 10.1042 10.7615 10.9996 7.99934 10.9996C5.23619 10.9996 2.99725 10.1042 2.99725 8.99875" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.99834 15.0013C5.23619 15.0013 2.99725 14.1059 2.99725 13.0004" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.9994 4.99707H13.0004V10.9996" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.0007 15.0013C21.0027 13.8958 18.7618 13.0004 16.0007 13.0004C13.2395 13.0004 10.9986 13.8958 10.9996 15.0013C10.9996 16.1067 13.2385 17.0021 16.0017 17.0021C18.7648 17.0021 21.0037 16.1067 21.0037 15.0013" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.0027 15.0013V19.0029C21.0027 20.1084 18.7638 21.0038 16.0017 21.0038C13.2385 21.0038 10.9996 20.1084 10.9996 19.0029V15.0013" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.99833 19.0029C5.23618 19.0029 2.99625 18.1075 2.99625 17.0021" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <ellipse cx="7.99833" cy="4.99708" rx="5.00208" ry="2.00083" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.99624 4.99707V17.0021" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.0025 4.99707V9.99915" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.0033 6.9979L18.0025 4.99707L16.0017 6.9979" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'team'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M2 19C2 16.8 3.8 15 6 15H10C12.2 15 14 16.8 14 19" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M10.5 6C11.9 7.4 11.9 9.6 10.5 10.9C9.1 12.2 6.9 12.3 5.6 10.9C4.3 9.5 4.2 7.4 5.5 6C6.8 4.6 9.1 4.7 10.5 6"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 14H19C20.7 14 22 15.3 22 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M19.3 6.69999C20.3 7.69999 20.3 9.29999 19.3 10.2C18.3 11.1 16.7 11.2 15.8 10.2C14.9 9.19999 14.8 7.59999 15.8 6.69999C16.7 5.79999 18.3 5.79999 19.3 6.69999"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'lock'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8 10V7V7C8 4.791 9.791 3 12 3V3C14.209 3 16 4.791 16 7V7V10" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 14V17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M17 21H7C5.895 21 5 20.105 5 19V12C5 10.895 5.895 10 7 10H17C18.105 10 19 10.895 19 12V19C19 20.105 18.105 21 17 21Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'certificate'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.0025" cy="14.5015" r="3.50146" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M16.0017 17.3745V22.0044L18.0025 21.004L20.0033 22.0044V17.3745" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M13.0004 21.0041H4.99709C3.89163 21.0041 2.99625 20.1087 2.99625 19.0032V4.99742C2.99625 3.89195 3.89163 2.99658 4.99709 2.99658H18.0025C19.108 2.99658 20.0033 3.89195 20.0033 4.99742V7.99867"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.99792 6.99826H16.0017" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M6.99792 10.9997H10.9996" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M6.99792 15.0017H9.99917" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'xml-doc-download'" width="24" height="24" viewBox="0 0 34 34" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M25.5555 9.54889L21.7833 5.77665C21.2831 5.27631 20.6046 4.995 19.8971 4.995H10.3305C8.85712 4.995 7.66272 6.1894 7.66272 7.66277V26.3372C7.66272 27.8106 8.85712 29.005 10.3305 29.005H23.6694C25.1428 29.005 26.3372 27.8106 26.3372 26.3372V11.435C26.3372 10.7275 26.0558 10.0491 25.5555 9.54889Z"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19.6677 16.3331L16.333 19.6678L14.3322 17.667" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'xml-sales'" width="24" height="24" viewBox="0 0 32 32" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M24 28H8C5.79067 28 4 26.2093 4 24V8C4 5.79067 5.79067 4 8 4H24C26.2093 4 28 5.79067 28 8V24C28 26.2093 26.2093 28 24 28Z"
        stroke="#068373" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.2507 13H22.0001V16.7493" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M22 13L16.7493 18.2507L13.7493 15.2507L10 19" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'xml-shoppings'" width="32" height="32" viewBox="0 0 32 32" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M24 4H8C5.79067 4 4 5.79067 4 8V24C4 26.2093 5.79067 28 8 28H24C26.2093 28 28 26.2093 28 24V8C28 5.79067 26.2093 4 24 4Z"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.2507 19H22.0001V15.2507" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M22 19L16.7493 13.7493L13.7493 16.7493L10 13" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'xml-profit'" width="32" height="32" viewBox="0 0 32 32" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4.00146 12C4.00146 13.4733 6.98546 14.6667 10.6681 14.6667" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6668 20C6.98547 20 4.00146 18.8066 4.00146 17.3333" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.332 6.66669H17.3333V10.6667" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M14.668 21.3333C14.668 22.8066 17.652 24 21.3346 24C25.0173 24 28.0013 22.8066 28.0013 21.3333"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.668 16C14.668 17.4733 17.652 18.6667 21.3346 18.6667C25.0173 18.6667 28.0013 17.4733 28.0013 16"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.3334 13.3333C17.6534 13.3333 14.6668 14.5266 14.6694 16" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.668 16V26.6667C14.668 28.14 17.652 29.3333 21.3346 29.3333" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.3333 13.3333C25.0133 13.3333 27.9999 14.5266 27.9973 16" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M27.9999 16V26.6667C27.9999 28.14 25.0159 29.3333 21.3333 29.3333" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6667 25.3334C6.98533 25.3334 4 24.14 4 22.6667" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M10.6667 9.33333C14.3486 9.33333 17.3333 8.13943 17.3333 6.66667C17.3333 5.19391 14.3486 4 10.6667 4C6.98477 4 4 5.19391 4 6.66667C4 8.13943 6.98477 9.33333 10.6667 9.33333Z"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.99992 6.66669V22.6667" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'xml-loss'" width="32" height="32" viewBox="0 0 32 32" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4.00146 12C4.00146 13.4733 6.98546 14.6667 10.6681 14.6667" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6668 20C6.98547 20 4.00146 18.8066 4.00146 17.3333" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.332 6.66669H17.3333V10.6667" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M14.668 21.3333C14.668 22.8066 17.652 24 21.3346 24C25.0173 24 28.0013 22.8066 28.0013 21.3333"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.668 16C14.668 17.4733 17.652 18.6667 21.3346 18.6667C25.0173 18.6667 28.0013 17.4733 28.0013 16"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.3334 13.3333C17.6534 13.3333 14.6668 14.5266 14.6694 16" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.668 16V26.6667C14.668 28.14 17.652 29.3333 21.3346 29.3333" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.3333 13.3333C25.0133 13.3333 27.9999 14.5266 27.9973 16" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M27.9999 16V26.6667C27.9999 28.14 25.0159 29.3333 21.3333 29.3333" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6667 25.3334C6.98533 25.3334 4 24.14 4 22.6667" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M10.6667 9.33333C14.3486 9.33333 17.3333 8.13943 17.3333 6.66667C17.3333 5.19391 14.3486 4 10.6667 4C6.98477 4 4 5.19391 4 6.66667C4 8.13943 6.98477 9.33333 10.6667 9.33333Z"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.99992 6.66669V22.6667" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <svg v-if="icon === 'billing'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 2.25C15.6909 2.25 16.6656 3.17516 16.7448 4.34595L16.75 4.5V14C16.75 14.4142 16.4142 14.75 16 14.75C15.6203 14.75 15.3065 14.4678 15.2568 14.1018L15.25 14V4.5C15.25 4.1203 14.9678 3.80651 14.6018 3.75685L14.5 3.75H3.5C3.1203 3.75 2.80651 4.03215 2.75685 4.39823L2.75 4.5V19.5C2.75 19.8797 3.03215 20.1935 3.39823 20.2432L3.5 20.25H14.5C14.8797 20.25 15.1935 19.9678 15.2432 19.6018L15.25 19.5V19.316C15.25 18.9018 15.5858 18.566 16 18.566C16.3797 18.566 16.6935 18.8482 16.7432 19.2142L16.75 19.316V19.5C16.75 20.6909 15.8248 21.6656 14.654 21.7448L14.5 21.75H3.5C2.30914 21.75 1.33436 20.8248 1.25519 19.654L1.25 19.5V4.5C1.25 3.30914 2.17516 2.33436 3.34595 2.25519L3.5 2.25H14.5Z"
        fill="currentColor" />
      <path
        d="M17.2918 8.25C17.7908 8.25 18.2839 8.34958 18.7425 8.54198L18.9688 8.64589L19.6228 8.97289C20.1336 9.22826 20.5783 9.59639 20.924 10.0482L21.066 10.2469L22.624 12.584C22.8538 12.9286 22.7607 13.3943 22.416 13.624C22.1027 13.8329 21.6894 13.7749 21.4442 13.5037L21.376 13.416L19.8179 11.0789C19.6369 10.8074 19.3995 10.5792 19.1227 10.4093L18.952 10.3145L18.298 9.98753C18.0481 9.86257 17.7776 9.78552 17.5006 9.75971L17.2918 9.75H16C15.5858 9.75 15.25 9.41421 15.25 9C15.25 8.6203 15.5322 8.30651 15.8982 8.25685L16 8.25H17.2918Z"
        fill="currentColor" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M5.18663 5.75493L6.20327 5.38894C6.71781 5.20369 7.28085 5.20369 7.79539 5.38894L8.81359 5.75567C9.37506 5.958 9.74952 6.49051 9.75 7.08793V8.22125C9.75 9.33072 8.84926 10.0553 7.57532 10.6261C7.20955 10.7913 6.79045 10.7913 6.42663 10.627C5.15259 10.0561 4.25 9.32965 4.25 8.22125V7.08809C4.24987 6.49074 4.62445 5.9575 5.18663 5.75493ZM8.25 8.22125V7.14599L7.28727 6.80026C7.10116 6.73325 6.8975 6.73325 6.71137 6.80026L5.749 7.14599L5.75 8.22125C5.75 8.46267 6.23063 8.86674 6.88742 9.18679L6.999 9.23899L7.11442 9.18597C7.72021 8.89085 8.17521 8.52489 8.24163 8.27987L8.25 8.22125Z"
        fill="currentColor" />
      <path
        d="M7 12.75C7.41421 12.75 7.75 13.0858 7.75 13.5C7.75 13.8797 7.46785 14.1935 7.10177 14.2432L7 14.25H5C4.58579 14.25 4.25 13.9142 4.25 13.5C4.25 13.1203 4.53215 12.8065 4.89823 12.7568L5 12.75H7Z"
        fill="currentColor" />
      <path
        d="M9 15.75C9.41421 15.75 9.75 16.0858 9.75 16.5C9.75 16.8797 9.46785 17.1935 9.10177 17.2432L9 17.25H5C4.58579 17.25 4.25 16.9142 4.25 16.5C4.25 16.1203 4.53215 15.8065 4.89823 15.7568L5 15.75H9Z"
        fill="currentColor" />
      <path
        d="M12.5842 10.251C13.1188 10.2664 13.6284 10.4701 14.0251 10.8225L14.1684 10.9615L16.327 13.249L17.5 13.25C17.8797 13.25 18.1935 13.5321 18.2432 13.8982L18.25 14C18.25 14.3797 17.9679 14.6935 17.6018 14.7431L17.5 14.75H16C15.7985 14.75 15.6239 14.6741 15.4938 14.5543L15.411 14.464L13.0775 11.991C12.9656 11.8725 12.8196 11.7933 12.6614 11.7634L12.5408 11.7503C12.3778 11.7456 12.2187 11.7928 12.0896 11.8797L11.9989 11.9521L11.9751 11.9751C11.7451 12.2051 11.6876 12.551 11.82 12.839L11.8786 12.944L14.8657 17.4374C15.2262 17.9797 15.7404 18.3998 16.3399 18.6455L16.5428 18.7209L21.2376 20.2886C21.6305 20.4198 21.8426 20.8447 21.7114 21.2375C21.5911 21.5977 21.2241 21.806 20.8612 21.7371L20.7625 21.7114L16.0677 20.1436C15.1482 19.8366 14.3468 19.2563 13.7686 18.4834L13.6166 18.2679L10.6294 13.7744C10.0646 12.9248 10.1396 11.8103 10.7997 11.0392L10.9222 10.9068L10.9615 10.8687C11.3991 10.4558 11.9828 10.2335 12.5842 10.251Z"
        fill="currentColor" />
    </svg>

    <svg v-if="icon === 'dctfweb'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M10.4851 9.78247C11.1226 9.78247 11.7001 9.91747 12.2176 10.1875C12.7426 10.4575 13.1588 10.8512 13.4663 11.3687C13.7738 11.8862 13.9276 12.4975 13.9276 13.2025V14.08C13.9276 14.785 13.7738 15.3962 13.4663 15.9137C13.1588 16.4312 12.7426 16.825 12.2176 17.095C11.7001 17.365 11.1226 17.5 10.4851 17.5H7.56006V9.78247H10.4851ZM9.30381 16.1162H10.4851C10.9651 16.1162 11.3551 15.9625 11.6551 15.655C11.9551 15.34 12.1051 14.8975 12.1051 14.3275V12.955C12.1051 12.385 11.9551 11.9462 11.6551 11.6387C11.3551 11.3237 10.9651 11.1662 10.4851 11.1662H9.30381V16.1162Z"
        fill="currentColor" />
    </svg>

    <svg v-if="icon === 'simples'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18.4135 6.414L15.5855 3.586C15.2105 3.211 14.7015 3 14.1715 3H6.99951C5.89451 3 4.99951 3.895 4.99951 5V19C4.99951 20.105 5.89451 21 6.99951 21H16.9995C18.1045 21 18.9995 20.105 18.9995 19V7.828C18.9995 7.298 18.7885 6.789 18.4135 6.414V6.414Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.9995 8H14.9995C14.4475 8 13.9995 7.552 13.9995 7V3" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12.3938 11.965C12.3938 11.68 12.2813 11.4587 12.0563 11.3012C11.8313 11.1362 11.52 11.0537 11.1225 11.0537C10.71 11.0537 10.3875 11.1212 10.155 11.2562C9.93005 11.3837 9.81755 11.5562 9.81755 11.7737C9.81755 11.9612 9.8888 12.1187 10.0313 12.2462C10.1738 12.3662 10.3538 12.4637 10.5713 12.5387C10.7963 12.6137 11.1 12.6962 11.4825 12.7862C12.045 12.9212 12.5063 13.06 12.8663 13.2025C13.2338 13.345 13.545 13.5662 13.8 13.8662C14.0625 14.1662 14.1938 14.5675 14.1938 15.07C14.1938 15.9475 13.9088 16.5925 13.3388 17.005C12.7763 17.41 12 17.6125 11.01 17.6125C10.11 17.6125 9.3638 17.4175 8.7713 17.0275C8.1863 16.63 7.8938 16.03 7.8938 15.2275L7.90505 14.98H9.54755L9.5363 15.16C9.5363 15.49 9.66005 15.7525 9.90755 15.9475C10.155 16.135 10.5038 16.2287 10.9538 16.2287C11.4788 16.2287 11.8538 16.1462 12.0788 15.9812C12.3113 15.8162 12.4275 15.6062 12.4275 15.3512C12.4275 15.1562 12.3563 14.9987 12.2138 14.8787C12.0788 14.7512 11.9025 14.6537 11.685 14.5862C11.475 14.5112 11.1788 14.4287 10.7963 14.3387C10.2263 14.1962 9.7613 14.0537 9.4013 13.9112C9.0413 13.7687 8.73005 13.54 8.46755 13.225C8.20505 12.91 8.0738 12.4862 8.0738 11.9537C8.0738 11.4587 8.2088 11.0387 8.4788 10.6937C8.7488 10.3487 9.1088 10.0937 9.5588 9.92873C10.0163 9.75623 10.5263 9.66998 11.0888 9.66998C11.9513 9.66998 12.6563 9.86123 13.2038 10.2437C13.7513 10.6187 14.025 11.1812 14.025 11.9312V12.0662H12.3938V11.965Z"
        fill="currentColor" />
    </svg>

    <svg v-if="icon === 'installments'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18.4135 6.414L15.5855 3.586C15.2105 3.211 14.7015 3 14.1715 3H6.99951C5.89451 3 4.99951 3.895 4.99951 5V19C4.99951 20.105 5.89451 21 6.99951 21H16.9995C18.1045 21 18.9995 20.105 18.9995 19V7.828C18.9995 7.298 18.7885 6.789 18.4135 6.414V6.414Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.9995 8H14.9995C14.4475 8 13.9995 7.552 13.9995 7V3" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 12L9 18M12 12L12 18M15 12L15 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    </svg>
    <svg v-if="icon === 'certificates'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path d="M18.4136 6.414L15.5856 3.586" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M15.5854 3.586C15.2104 3.211 14.7014 3 14.1714 3" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.1715 3H6.99951" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M6.99951 3C5.89451 3 4.99951 3.895 4.99951 5" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.99951 5V19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.99951 19C4.99951 20.105 5.89451 21 6.99951 21" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.9995 21C18.1045 21 18.9995 20.105 18.9995 19" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18.9995 19V7.828" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M18.9995 7.828C18.9995 7.298 18.7885 6.789 18.4135 6.414" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 21L16.5 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M18.9995 8H14.9995C14.4475 8 13.9995 7.552 13.9995 7V3" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <circle cx="11.25" cy="14.5714" r="3.75" stroke="currentColor" stroke-width="1.60647" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M9.10718 17.6486V22.6072L11.25 21.5358L13.3929 22.6072V17.6486" stroke="currentColor"
        stroke-width="1.60647" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'mailarrow'" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M20.992 6.78394V13.9407"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.0161 20.0848H15.8721"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.968 18.0367L22.016 20.0847L19.968 22.1327"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.776 18.0367H5.63205C4.50053 18.0367 3.58405 17.1202 3.58405 15.9887V6.78394"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.64331 4.72473H18.9338C20.0704 4.72473 20.9931 5.64633 20.9931 6.784V6.784C20.9931 7.467 20.6541 8.10496 20.0889 8.48793L14.6033 12.2061C13.2055 13.1533 11.3705 13.1533 9.97278 12.2061L4.48824 8.48896C3.92299 8.10598 3.58405 7.467 3.58405 6.78502V6.784C3.58405 5.64633 4.50565 4.72473 5.64331 4.72473Z"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg v-if="icon === 'mailboxes'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M4.636 5.04498H19.363C20.267 5.04498 21 5.77798 21 6.68198V17.318C21 18.222 20.267 18.954 19.364 18.954H4.636C3.733 18.955 3 18.222 3 17.318V6.68198C3 5.77798 3.733 5.04498 4.636 5.04498Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M3.11902 6.07497L10.813 11.578C11.508 12.075 12.442 12.076 13.138 11.58L20.876 6.06097"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'attachment'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.879 8.37503L8.393 13.861C7.567 14.687 7.567 16.027 8.393 16.853V16.853C9.219 17.679 10.559 17.679 11.385 16.853L18.617 9.62103C20.132 8.10603 20.132 5.65003 18.617 4.13503V4.13503C17.102 2.62003 14.646 2.62003 13.131 4.13503L5.899 11.367C3.695 13.571 3.695 17.143 5.899 19.347V19.347C8.103 21.551 11.675 21.551 13.879 19.347L18.268 14.958"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'fgts'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M24.5514 8.552L20.7807 4.78133C20.2807 4.28133 19.602 4 18.8953 4H9.33268C7.85935 4 6.66602 5.19333 6.66602 6.66667V25.3333C6.66602 26.8067 7.85935 28 9.33268 28H22.666C24.1393 28 25.3327 26.8067 25.3327 25.3333V10.4373C25.3327 9.73067 25.0514 9.052 24.5514 8.552V8.552Z"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M25.3327 10.6667H19.9993C19.2633 10.6667 18.666 10.0693 18.666 9.33333V4" stroke="currentColor"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.8 15.555H13.36V18.015H17.35V19.86H13.36V24H11.08V13.71H18.04L17.8 15.555Z" fill="currentColor" />
    </svg>
    <svg v-if="icon === 'listen'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 13.762L12.194 17.928C12.419 19.281 11.376 20.512 10.005 20.512V20.512C8.92 20.512 7.994 19.728 7.816 18.657L7 13.762"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M13.75 13.762H5.875C4.011 13.762 2.5 12.251 2.5 10.387V10.387C2.5 8.52299 4.011 7.01199 5.875 7.01199H13.75"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M13.75 7.01199L18.751 3.67799C19.499 3.17999 20.5 3.71599 20.5 4.61499V16.16C20.5 17.059 19.499 17.594 18.751 17.096L13.75 13.762"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.75 13.76V7.00999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'user-opinion'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 21V21C1 19.001 2.62 17.381 4.619 17.381H8.238C10.237 17.381 11.857 19.001 11.857 21V21"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M8.6684 9.26059C9.90519 10.4974 9.90519 12.5026 8.6684 13.7394C7.43161 14.9762 5.42638 14.9762 4.18959 13.7394C2.9528 12.5026 2.9528 10.4974 4.18959 9.26059C5.42638 8.0238 7.43161 8.0238 8.6684 9.26059"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M18.096 13.808C20.804 13.808 23 11.612 23 8.904C23 6.196 20.805 4 18.096 4C15.387 4 13.192 6.196 13.192 8.904"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M18.101 13.813C17.363 13.813 16.664 13.65 16.036 13.359L13.018 14L13.649 10.976C13.356 10.346 13.192 9.644 13.192 8.904"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M15.8134 8.96464C15.8329 8.98417 15.8329 9.01582 15.8134 9.03535C15.7939 9.05488 15.7622 9.05488 15.7427 9.03535C15.7232 9.01582 15.7232 8.98417 15.7427 8.96464C15.7622 8.94511 15.7939 8.94511 15.8134 8.96464"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M18.0634 8.96464C18.0829 8.98417 18.0829 9.01582 18.0634 9.03535C18.0439 9.05488 18.0122 9.05488 17.9927 9.03535C17.9732 9.01582 17.9732 8.98417 17.9927 8.96464C18.0122 8.94511 18.0439 8.94511 18.0634 8.96464"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M20.3134 8.96464C20.3329 8.98417 20.3329 9.01582 20.3134 9.03535C20.2939 9.05488 20.2622 9.05488 20.2427 9.03535C20.2232 9.01582 20.2232 8.98417 20.2427 8.96464C20.2622 8.94511 20.2939 8.94511 20.3134 8.96464"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'paper-check'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18 3H6C4.895 3 4 3.895 4 5V19C4 20.105 4.895 21 6 21H18C19.105 21 20 20.105 20 19V5C20 3.895 19.105 3 18 3Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14 7.5L11.5 10L10 8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M8 14H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 17H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'checkup360'" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#2FB490" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M19.5031 12V12C19.5031 16.1439 16.1439 19.5031 12 19.5031V19.5031C7.85614 19.5031 4.49688 16.1439 4.49688 12V12C4.49688 7.85615 7.85614 4.49689 12 4.49689V4.49689C13.99 4.49689 15.8984 5.28739 17.3055 6.6945C18.7126 8.10161 19.5031 10.0101 19.5031 12V12Z"
        stroke="#1E293B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.49688 12H7.73572L9.41559 9.48813L11.6482 14.5119L13.3189 12H15.5515" stroke="#1E293B"
        stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'late'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <circle id="Oval" cx="12" cy="12" r="9.00375" stroke="#E8BB34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path" d="M15.4554 13.1515L12 12V5.9975" stroke="#E8BB34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
    <svg v-if="icon === 'nearFine'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <g id="Group_2">
          <path id="Path" d="M12 13.12V9.38" stroke="#FF7E20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path id="Path_2" d="M11.999 16.125C11.861 16.125 11.749 16.237 11.75 16.375C11.75 16.513 11.862 16.625 12 16.625C12.138 16.625 12.25 16.513 12.25 16.375C12.25 16.237 12.138 16.125 11.999 16.125" stroke="#FF7E20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path id="Path_3" fill-rule="evenodd" clip-rule="evenodd" d="M14.029 4.139L21.688 17.543C22.578 19.101 21.453 21.04 19.659 21.04H4.341C2.546 21.04 1.421 19.101 2.312 17.543L9.971 4.139C10.868 2.568 13.132 2.568 14.029 4.139Z" stroke="#FF7E20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
      </g>
    </svg>
    <svg v-if="icon === 'fined'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <rect id="Rectangle" x="2.99625" y="2.99625" width="18.0075" height="18.0075" rx="4" stroke="#EB5454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M9.07279 9.1058L7.58617 10.2913C7.25095 10.559 7.04537 10.9567 7.02075 11.385C6.99613 11.8133 7.15478 12.2318 7.45711 12.5362L8.43652 13.5206C8.73892 13.8248 9.15656 13.9858 9.58488 13.9632C10.0132 13.9407 10.4116 13.7368 10.6805 13.4026L11.873 11.921C12.1828 11.5359 12.5341 11.186 12.9204 10.8775L14.407 9.69204C14.7425 9.4245 14.9483 9.02681 14.9729 8.5984C14.9976 8.16999 14.8387 7.75134 14.5361 7.44711L13.5567 6.4627C13.2543 6.15853 12.8366 5.99756 12.4083 6.0201C11.98 6.04263 11.5815 6.24653 11.3127 6.58075L10.1212 8.06236C9.81124 8.44772 9.45962 8.79766 9.07279 9.1058V9.1058Z" stroke="#EB5454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path_2" d="M10.6494 17.0021H6.99792" stroke="#EB5454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path_3" d="M13.3356 10.5464L17.4163 14.0008C17.902 14.4135 18.1183 15.0619 17.9775 15.6835C17.8367 16.3051 17.3622 16.797 16.746 16.9601C16.1299 17.1232 15.4742 16.9304 15.0443 16.4599L11.4268 12.4742" stroke="#EB5454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
    <svg v-if="icon === 'userplus'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 5.2C16.7 6.9 16.7 9.6 15 11.2C13.3 12.8 10.6 12.9 9 11.2C7.4 9.5 7.3 6.8 9 5.2C10.7 3.6 13.3 3.6 15 5.2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 20C4 17.5 6 15.5 8.5 15.5H11.1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.5 20.5V15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 18H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'circle-minus'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M16 12H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'circle-x'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M14.83 9.17L9.16998 14.83" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.83 14.83L9.16998 9.17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'account-information'" width="15" height="15" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.254 10.75C12.6337 10.75 12.9475 11.0322 12.9972 11.3982L13.004 11.5V15.75C13.004 16.1642 12.6682 16.5 12.254 16.5C11.8743 16.5 11.5605 16.2178 11.5108 15.8518L11.504 15.75V12.25H11.104C10.7243 12.25 10.4105 11.9678 10.3608 11.6018L10.354 11.5C10.354 11.1203 10.6362 10.8065 11.0022 10.7568L11.104 10.75H12.254Z" fill="currentColor"/>
      <path d="M13.4 15C13.8142 15 14.15 15.3358 14.15 15.75C14.15 16.1297 13.8678 16.4435 13.5018 16.4932L13.4 16.5H11.1C10.6858 16.5 10.35 16.1642 10.35 15.75C10.35 15.3703 10.6322 15.0565 10.9982 15.0068L11.1 15H13.4Z" fill="currentColor"/>
      <path d="M11.9444 7.5C12.21 7.49966 12.4649 7.60504 12.6528 7.79289C12.8093 7.94944 12.9086 8.15252 12.9371 8.36961L12.9456 8.50128C12.945 9.01381 12.5589 9.43584 12.0618 9.49333L11.9452 9.5C11.3933 9.49976 10.9459 9.05238 10.9457 8.50043C10.9454 7.94848 11.3924 7.50071 11.9444 7.5Z" fill="currentColor"/>
      <path d="M19.2129 6.58066C19.5632 6.35962 20.0264 6.4644 20.2474 6.8147C22.6757 10.663 22.1153 15.6798 18.8978 18.8974C15.6802 22.115 10.6633 22.6754 6.81503 20.2471C6.46473 20.0261 6.35995 19.5629 6.58099 19.2126C6.80204 18.8623 7.26521 18.7575 7.61551 18.9786C10.871 21.0328 15.1151 20.5587 17.8371 17.8368C20.5591 15.1148 21.0332 10.8707 18.9789 7.61518C18.7578 7.26488 18.8626 6.80171 19.2129 6.58066Z" fill="currentColor"/>
      <path d="M13.2687 3.06405C13.3607 2.66018 13.7627 2.40737 14.1666 2.49937C15.2365 2.74308 16.2572 3.16684 17.1851 3.75254C17.5353 3.97363 17.64 4.43682 17.419 4.78709C17.1979 5.13736 16.7347 5.24208 16.3844 5.02099C15.6002 4.526 14.7376 4.16787 13.8334 3.9619C13.4296 3.8699 13.1767 3.46792 13.2687 3.06405Z" fill="currentColor"/>
      <path d="M3.06413 13.2689C3.468 13.1769 3.86998 13.4297 3.96198 13.8336C4.16795 14.7378 4.52608 15.6004 5.02106 16.3846C5.24216 16.7348 5.13744 17.198 4.78717 17.4191C4.43689 17.6402 3.97371 17.5355 3.75262 17.1852C3.16692 16.2573 2.74316 15.2366 2.49944 14.1667C2.40744 13.7629 2.66026 13.3609 3.06413 13.2689Z" fill="currentColor"/>
      <path d="M9.83342 2.49937C10.2373 2.40737 10.6393 2.66018 10.7313 3.06405C10.8233 3.46792 10.5704 3.8699 10.1666 3.9619C9.2624 4.16787 8.39979 4.526 7.6156 5.02099C7.26532 5.24208 6.80214 5.13736 6.58105 4.78709C6.35995 4.43682 6.46467 3.97363 6.81494 3.75254C7.74285 3.16684 8.76354 2.74308 9.83342 2.49937Z" fill="currentColor"/>
      <path d="M3.75262 6.81462C3.97371 6.46434 4.43689 6.35962 4.78717 6.58072C5.13744 6.80181 5.24216 7.26499 5.02106 7.61527C4.52608 8.39946 4.16795 9.26207 3.96198 10.1663C3.86998 10.5701 3.468 10.8229 3.06413 10.7309C2.66026 10.6389 2.40744 10.237 2.49944 9.83309C2.74316 8.76321 3.16692 7.74252 3.75262 6.81462Z" fill="currentColor"/>
    </svg>
    <svg v-if="icon === 'user-feedback'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.327 13.363C15.472 13.363 14.663 13.174 13.936 12.837L10.441 13.579L11.172 10.077C10.833 9.348 10.643 8.535 10.643 7.678C10.643 4.543 13.185 2 16.322 2C19.459 2 22 4.543 22 7.678C22 10.813 19.457 13.356 16.322 13.356" stroke="#currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2 18.371L14.23 17.401C13.745 16.916 12.96 16.916 12.476 17.401L11.714 18.163C11.544 18.333 11.286 18.39 11.066 18.294C9.961 17.81 8.87001 17.077 7.89701 16.104C6.92801 15.135 6.19701 14.049 5.71301 12.948C5.61201 12.721 5.671 12.454 5.847 12.277L6.53 11.594C7.085 11.039 7.08501 10.255 6.60001 9.77L5.629 8.8C4.983 8.154 3.937 8.154 3.291 8.8L2.752 9.339C2.139 9.952 1.884 10.836 2.049 11.712C2.457 13.872 3.71301 16.238 5.737 18.263C7.761 20.288 10.127 21.543 12.288 21.951C13.164 22.116 14.048 21.861 14.661 21.248L15.2 20.71C15.845 20.064 15.845 19.017 15.2 18.371Z" stroke="#currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.6724 7.75464C13.6919 7.77417 13.6919 7.80582 13.6724 7.82535C13.6529 7.84488 13.6212 7.84488 13.6017 7.82535C13.5822 7.80582 13.5822 7.77417 13.6017 7.75464C13.6212 7.73511 13.6529 7.73511 13.6724 7.75464" stroke="#currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.2784 7.75464C16.2979 7.77417 16.2979 7.80582 16.2784 7.82535C16.2589 7.84488 16.2272 7.84488 16.2077 7.82535C16.1882 7.80582 16.1882 7.77417 16.2077 7.75464C16.2272 7.73511 16.2589 7.73511 16.2784 7.75464" stroke="#currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.8834 7.75464C18.9029 7.77417 18.9029 7.80582 18.8834 7.82535C18.8639 7.84488 18.8322 7.84488 18.8127 7.82535C18.7932 7.80582 18.7932 7.77417 18.8127 7.75464C18.8322 7.73511 18.8639 7.73511 18.8834 7.75464" stroke="#currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'benefit'" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"
      fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M18 3H6C4.895 3 4 3.895 4 5V19C4 20.105 4.895 21 6 21H18C19.105 21 20 20.105 20 19V5C20 3.895 19.105 3 18 3Z"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14 7.5L11.5 10L10 8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M8 14H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 17H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'subscription'" width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0025 14.0007V19.0028C18.0025 20.1078 17.1067 21.0036 16.0017 21.0036H4.99708C3.89205 21.0036 2.99625 20.1078 2.99625 19.0028V4.99693C2.99625 3.8919 3.89205 2.99609 4.99708 2.99609H16.0017C17.1067 2.99609 18.0025 3.8919 18.0025 4.99693V7.99818" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0004 13.4143V16.0014H15.5875C15.8528 16.0014 16.1072 15.8959 16.2948 15.7083L20.3825 11.6206C21.2109 10.7917 21.2109 9.44827 20.3825 8.61934V8.61934C19.5536 7.79095 18.2102 7.79095 17.3812 8.61934L13.2935 12.707C13.1059 12.8946 13.0004 13.149 13.0004 13.4143Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.99792 12.0002H9.99917" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.99792 16.0022H9.99917" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.99792 7.99826H14.0008" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'digital-certificate'" width="15" height="15" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.0025" cy="14.5015" r="3.50146" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M16.0017 17.3745V22.0044L18.0025 21.004L20.0033 22.0044V17.3745" stroke="currentColor" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M13.0004 21.0041H4.99709C3.89163 21.0041 2.99625 20.1087 2.99625 19.0032V4.99742C2.99625 3.89195 3.89163 2.99658 4.99709 2.99658H18.0025C19.108 2.99658 20.0033 3.89195 20.0033 4.99742V7.99867"
        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.99792 6.99826H16.0017" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M6.99792 10.9997H10.9996" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
      <path d="M6.99792 15.0017H9.99917" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-if="icon === 'star-list'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0038 18.0026H14.472" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.0038 15.0012H14.472" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.8268 21.0036H14.472" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.8345 17.0754L7.37707 18.8762C7.01728 19.0661 6.58091 19.0357 6.25098 18.7976C5.92105 18.5596 5.75459 18.155 5.82142 17.7537L6.59775 13.2899L3.32238 10.1265C3.02976 9.84384 2.92428 9.41885 3.05077 9.03214C3.17727 8.64542 3.51351 8.36491 3.91663 8.30979L8.43551 7.65852L10.4544 3.59082C10.6369 3.22658 11.0094 2.99658 11.4168 2.99658C11.8242 2.99658 12.1967 3.22658 12.3792 3.59082L14.401 7.65852L18.9199 8.30879C19.3226 8.3642 19.6583 8.64453 19.7848 9.03085C19.9112 9.41717 19.8061 9.84177 19.5141 10.1245L17.6684 11.9093" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'enter'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 18.998V7.50298C11 6.81298 10.645 6.17198 10.06 5.80698L6.06 3.30698C4.728 2.47498 3 3.43198 3 5.00298V16.497C3 17.187 3.355 17.828 3.94 18.193L7.94 20.693C9.272 21.526 11 20.568 11 18.998Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21 11H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 9L15 11L17 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 19H15C16.105 19 17 18.105 17 17V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 6V5C17 3.895 16.105 3 15 3H5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'chat-bot'" width="18" height="18" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.12695 19.6669C3.12695 13.4845 8.13877 8.47266 14.3212 8.47266H22.8054C28.9878 8.47266 33.9996 13.4845 33.9996 19.6669C33.9996 25.8493 28.9878 30.8611 22.8054 30.8611H14.3212C8.13878 30.8611 3.12695 25.8493 3.12695 19.6669Z" fill="#3868C7"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.13856 -0.0009767C4.87194 -0.000976775 6.27713 1.40421 6.27713 3.13759L6.27713 19.6674L3.13856 19.6674L8.5973e-07 19.6674L1.67678e-07 3.13759C9.19094e-08 1.40421 1.40518 -0.000976624 3.13856 -0.0009767Z" fill="#76C451"/>
      <path d="M17.7917 19.6659C17.7917 21.3993 16.4016 22.8045 14.6868 22.8045C12.9721 22.8045 11.582 21.3993 11.582 19.6659C11.582 17.9325 12.9721 16.5273 14.6868 16.5273C16.4016 16.5273 17.7917 17.9325 17.7917 19.6659Z" fill="white"/>
      <path d="M27.6215 19.6659C27.6215 21.3993 26.2314 22.8045 24.5167 22.8045C22.8019 22.8045 21.4118 21.3993 21.4118 19.6659C21.4118 17.9325 22.8019 16.5273 24.5167 16.5273C26.2314 16.5273 27.6215 17.9325 27.6215 19.6659Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 19.6677C0 11.7542 6.41105 5.33496 14.3244 5.33496H16.0067C17.7401 5.33496 19.1452 6.74014 19.1452 8.47352C19.1452 10.2069 17.7401 11.6121 16.0067 11.6121H14.3244C9.88223 11.6121 6.27713 15.2165 6.27713 19.6677C6.27713 24.119 9.88223 27.7234 14.3244 27.7234H22.4581C24.1915 27.7234 25.5967 29.1286 25.5967 30.8619C25.5967 32.5953 24.1915 34.0005 22.4581 34.0005H14.3244C6.41105 34.0005 0 27.5813 0 19.6677Z" fill="#76C451"/>
    </svg>
    <svg v-if="icon === 'item-list'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 3H5C3.895 3 3 3.895 3 5V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.444 21H8.556C7.696 21 7 20.304 7 19.444V8.556C7 7.696 7.696 7 8.556 7H19.445C20.304 7 21 7.696 21 8.556V19.445C21 20.304 20.304 21 19.444 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.89 10.8899H13.22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.22 14H17.89" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.22 17.1101H17.89" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.11 16.9171C10.003 16.9171 9.916 17.0041 9.916 17.1111C9.916 17.2181 10.003 17.3051 10.11 17.3051C10.217 17.3051 10.304 17.2181 10.304 17.1111C10.304 17.0041 10.218 16.9171 10.11 16.9171" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.11 13.806C10.003 13.806 9.916 13.893 9.916 14C9.916 14.107 10.003 14.194 10.11 14.194C10.217 14.194 10.304 14.107 10.304 14C10.304 13.893 10.218 13.806 10.11 13.806" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.11 10.6939C10.003 10.6939 9.916 10.7809 9.916 10.8879C9.916 10.9949 10.003 11.0819 10.11 11.0819C10.217 11.0819 10.304 10.9949 10.304 10.8879C10.306 10.7819 10.218 10.6939 10.11 10.6939" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'money-exchange'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.49771 2.49603L7.99834 3.99666L6.49771 5.49728" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.99833 3.99664H5.9975C4.33995 3.99664 2.99625 5.34035 2.99625 6.99789" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.5023 21.5039L16.0017 20.0033L17.5023 18.5027" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.0017 20.0033H18.0025C19.66 20.0033 21.0037 18.6596 21.0037 17.0021" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.49895 20.0033C6.46012 20.0033 3.99666 17.5398 3.99666 14.501C3.99666 11.4622 6.46012 8.99872 9.49895 8.99872C12.5378 8.99872 15.0012 11.4622 15.0012 14.501C15.0012 15.9603 14.4215 17.3598 13.3897 18.3917C12.3578 19.4236 10.9582 20.0033 9.49895 20.0033" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.68753 13.4795L9.49886 12.6662V15.9155" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.68962 15.9175H10.3081" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.6102 5.66047C12.759 3.51169 16.2429 3.5117 18.3917 5.66048C20.5404 7.80926 20.5404 11.2931 18.3917 13.4419" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'magic-star'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99625 16.7941C5.20631 16.7941 6.99792 18.5858 6.99792 20.7958C6.99792 18.5858 8.78952 16.7941 10.9996 16.7941C8.78952 16.7941 6.99792 15.0025 6.99792 12.7925C6.99792 15.0025 5.20631 16.7941 2.99625 16.7941Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0004 13.7927C15.2105 13.7927 17.0021 15.5843 17.0021 17.7943C17.0021 15.5843 18.7937 13.7927 21.0037 13.7927C18.7937 13.7927 17.0021 12.0011 17.0021 9.79102C17.0021 12.0011 15.2105 13.7927 13.0004 13.7927V13.7927Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99792 6.78975C9.20798 6.78975 10.9996 8.58136 10.9996 10.7914C10.9996 8.58136 12.7912 6.78975 15.0013 6.78975C12.7912 6.78975 10.9996 4.99815 10.9996 2.78809C10.9996 4.99815 9.20798 6.78975 6.99792 6.78975Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'clock-calendar'" class="mr-1" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17.5" cy="16.5" r="4.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 10H11" stroke-width="1.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.5 2V5" stroke-width="1.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.5 2V5" stroke-width="1.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 20H6C4.34315 20 3 18.6569 3 17V6.5C3 4.84315 4.34315 3.5 6 3.5H18C19.6569 3.5 21 4.84315 21 6.5V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 14H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.393 14.9833V16.7369L18.7752 17.58" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'star-delete'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0017 22.0042C13.2395 22.0042 10.9996 19.7642 10.9996 17.0021C10.9996 14.2399 13.2395 12 16.0017 12C18.7648 12 21.0037 14.2399 21.0037 17.0021C21.0037 19.7642 18.7648 22.0042 16.0017 22.0042" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.5871 15.5874L17.4162 18.4166" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.5871 18.4166L17.4162 15.5874" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.3437 18.8349L7.45409 20.8638C6.56973 21.325 5.53429 20.5807 5.70336 19.6013L6.57173 14.5762L2.89219 11.0177C2.17389 10.3244 2.57006 9.11589 3.56047 8.97383L8.64459 8.24053L10.9155 3.66462C11.3567 2.77325 12.6373 2.77325 13.0804 3.66462L15.3554 8.24053L20.4395 8.97183C21.4299 9.11389 21.8241 10.3224 21.1078 11.0157L19.0319 13.0235" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'star'" width="24" height="24" viewBox="0 0 72 72" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36 51.705L18.537 60L22.164 41.64L9 28.176L27.537 25.863L36 9L44.463 25.863L63 28.176L49.836 41.64L53.463 60L36 51.705Z" stroke="currentColor" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'star-search'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3767 17.9547L7.68021 19.8715C7.29687 20.0743 6.83178 20.0428 6.47929 19.7901C6.1268 19.5375 5.94758 19.1072 6.01652 18.679L6.84186 13.933L3.3454 10.5726C3.03292 10.2723 2.92021 9.81951 3.05546 9.40778C3.19072 8.99605 3.54999 8.69828 3.97967 8.6418L8.81168 7.94951L10.9696 3.62771C11.1662 3.24051 11.5637 2.99658 11.998 2.99658C12.4323 2.99658 12.8298 3.24051 13.0264 3.62771L15.1893 7.94951L20.0213 8.6398C20.4508 8.69626 20.8099 8.99371 20.9453 9.40511C21.0807 9.8165 20.9685 10.2691 20.6566 10.5696L18.6838 12.4654" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.5947 15.4096C19.3656 16.1787 19.597 17.3367 19.1808 18.343C18.7646 19.3494 17.7829 20.0057 16.6939 20.0057C15.6049 20.0057 14.6233 19.3494 14.2071 18.343C13.7909 17.3367 14.0223 16.1787 14.7932 15.4096C15.8434 14.3609 17.5445 14.3609 18.5947 15.4096" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.3835 21.0041L18.5947 19.2153" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'emoji-happy'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <rect fill="#f8de40" x="1" y="1" width="22" height="22" rx="7.656"/>
      <path fill="#864e20" d="M7.055,7.313A1.747,1.747,0,1,0,8.8,9.059,1.747,1.747,0,0,0,7.055,7.313Z"/>
      <path fill="#864e20" d="M16.958,7.313A1.747,1.747,0,1,0,18.7,9.059,1.747,1.747,0,0,0,16.958,7.313Z"/>
      <path fill="#e7c930" d="M23,13.938a14.69,14.69,0,0,1-12.406,6.531c-5.542,0-6.563-1-9.142-2.529A7.66,7.66,0,0,0,8.656,23h6.688A7.656,7.656,0,0,0,23,15.344Z"/>
      <path fill="#864e20" d="M16.6,12.25A8.622,8.622,0,0,1,12,13.521,8.622,8.622,0,0,1,7.4,12.25s-.451-.273-.169.273,1.867.93,1.882,1.133a4.862,4.862,0,0,0,5.782,0c.015-.2,1.6-.586,1.882-1.133S16.6,12.25,16.6,12.25Z"/>
      <path fill="#e7c930" d="M14.422,14.961a4.8,4.8,0,0,1-4.844,0c-.424-.228-.476.164.352.656a4.093,4.093,0,0,0,2.07.656,4.093,4.093,0,0,0,2.07-.656C14.9,15.125,14.846,14.733,14.422,14.961Z"/>
    </svg>
    <svg v-if="icon === 'emoji-love'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <rect fill="#f8de40" x="1" y="1" width="22" height="22" rx="7.656"/>
      <path fill="#e7c930" d="M23,13.938a14.69,14.69,0,0,1-12.406,6.531c-5.542,0-6.563-1-9.142-2.529A7.66,7.66,0,0,0,8.656,23h6.688A7.656,7.656,0,0,0,23,15.344Z"/>
      <path fill="#f06880" d="M9.58,6.983A1.528,1.528,0,0,0,7.5,7.1l-.449.45L6.6,7.1a1.529,1.529,0,0,0-2.083-.113,1.472,1.472,0,0,0-.058,2.136L6.68,11.34a.518.518,0,0,0,.737,0l2.22-2.221A1.471,1.471,0,0,0,9.58,6.983Z"/>
      <path fill="#f06880" d="M19.483,6.983A1.528,1.528,0,0,0,17.4,7.1l-.449.45L16.5,7.1a1.529,1.529,0,0,0-2.083-.113,1.471,1.471,0,0,0-.057,2.136l2.221,2.221a.517.517,0,0,0,.736,0l2.221-2.221A1.472,1.472,0,0,0,19.483,6.983Z"/>
      <path fill="#864e20" d="M16.666,12.583H7.334a.493.493,0,0,0-.492.544c.123,1.175.875,3.842,5.158,3.842s5.035-2.667,5.158-3.842A.493.493,0,0,0,16.666,12.583Z"/>
      <path fill="#f06880" d="M12,16.969a6.538,6.538,0,0,0,2.959-.6,1.979,1.979,0,0,0-1.209-.853c-1.344-.3-1.75.109-1.75.109s-.406-.406-1.75-.109a1.979,1.979,0,0,0-1.209.853A6.538,6.538,0,0,0,12,16.969Z"/>
    </svg>
    <svg v-if="icon === 'emoji-angry'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <rect fill="#e7253e" x="1" y="1" width="22" height="22" rx="7.656"/>
      <path fill="#b51f36" d="M23,13.938a14.69,14.69,0,0,1-12.406,6.531c-5.542,0-6.563-1-9.142-2.529A7.66,7.66,0,0,0,8.656,23h6.688A7.656,7.656,0,0,0,23,15.344Z"/>
      <path fill="#90192d" d="M9.565,8.513A16.121,16.121,0,0,1,5.63,6.328a.52.52,0,0,0-.832.4c-.048,1.583.151,4.483,2.258,4.483A2.6,2.6,0,0,0,9.9,9.1.517.517,0,0,0,9.565,8.513Z"/>
      <path fill="#90192d" d="M19.2,6.731a.521.521,0,0,0-.832-.4,16.121,16.121,0,0,1-3.935,2.185A.517.517,0,0,0,14.1,9.1a2.6,2.6,0,0,0,2.84,2.11C19.051,11.214,19.25,8.314,19.2,6.731Z"/>
      <path fill="#90192d" d="M14,13.937a.318.318,0,0,1-.313-.326,2.105,2.105,0,0,0-.5-1.331A1.6,1.6,0,0,0,12,11.847a1.6,1.6,0,0,0-1.187.43,2.092,2.092,0,0,0-.5,1.335.32.32,0,0,1-.64.012,2.715,2.715,0,0,1,.679-1.792A2.211,2.211,0,0,1,12,11.207a2.211,2.211,0,0,1,1.647.625,2.721,2.721,0,0,1,.679,1.792A.321.321,0,0,1,14,13.937Z"/>
    </svg>
    <svg v-if="icon === 'more-options'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.999 11.625C11.792 11.625 11.624 11.793 11.626 12C11.626 12.207 11.794 12.375 12.001 12.375C12.208 12.375 12.376 12.207 12.376 12C12.375 11.793 12.207 11.625 11.999 11.625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.999 11.625C15.792 11.625 15.624 11.793 15.626 12C15.626 12.207 15.794 12.375 16.001 12.375C16.208 12.375 16.376 12.207 16.376 12C16.375 11.793 16.207 11.625 15.999 11.625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.999 11.625C7.792 11.625 7.624 11.793 7.626 12C7.626 12.207 7.794 12.375 8.001 12.375C8.208 12.375 8.376 12.207 8.376 12C8.375 11.793 8.207 11.625 7.999 11.625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'menu'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 5H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 12H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.879 5H7.122" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 7.12091V2.87891" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 19H21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.414 10.586C7.195 11.367 7.195 12.633 6.414 13.414C5.633 14.195 4.367 14.195 3.586 13.414C2.805 12.633 2.805 11.367 3.586 10.586C4.367 9.80499 5.633 9.80499 6.414 10.586" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.414 17.586C7.195 18.367 7.195 19.633 6.414 20.414C5.633 21.195 4.367 21.195 3.586 20.414C2.805 19.633 2.805 18.367 3.586 17.586C4.367 16.805 5.633 16.805 6.414 17.586" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'filter'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 13.5L19.707 8.293C19.895 8.105 20 7.851 20 7.586V5C20 4.448 19.552 4 19 4H5C4.448 4 4 4.448 4 5V7.586C4 7.851 4.105 8.106 4.293 8.293L9.5 13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.5 13.5V19.749C9.5 20.562 10.264 21.159 11.053 20.962L13.553 20.337C14.109 20.198 14.5 19.698 14.5 19.124V13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'expiringSoon'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 11V7.828C19 7.298 18.789 6.789 18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.542 14.758L21.807 18.783C22.364 19.774 21.648 21 20.511 21H15.982C14.844 21 14.129 19.776 14.686 18.783L16.951 14.758C17.518 13.747 18.973 13.747 19.542 14.758V14.758Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'valid'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5019 22.0042C14.0158 22.0042 12 19.9883 12 17.5023C12 15.0163 14.0158 13.0004 16.5019 13.0004C18.9889 13.0004 21.0037 15.0163 21.0037 17.5023C21.0037 19.9883 18.9889 22.0042 16.5019 22.0042" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.0025 9.99917V7.82627C18.0025 7.29605 17.7914 6.78683 17.4163 6.41168L14.5871 3.5825C14.2119 3.20734 13.7027 2.99625 13.1725 2.99625H5.9975C4.89204 2.99625 3.99667 3.89163 3.99667 4.99709V19.0029C3.99667 20.1084 4.89204 21.0038 5.9975 21.0038H9.99917" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.0025 7.99834H14.0008C13.4486 7.99834 13.0004 7.55015 13.0004 6.99792V2.99625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.1596 16.674L16.0867 18.7468L14.8442 17.5023" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'noCertificate'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 11V7.828C19 7.298 18.789 6.789 18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.489 20.544L14.456 19.511C14.164 19.22 14 18.824 14 18.412V16.59C14 16.178 14.164 15.782 14.456 15.49L15.489 14.457C15.78 14.164 16.176 14 16.588 14H18.41C18.822 14 19.218 14.164 19.51 14.456L20.544 15.49C20.836 15.782 21 16.177 21 16.59V18.412C21 18.824 20.836 19.22 20.544 19.512L19.51 20.546C19.22 20.836 18.824 21 18.411 21H16.589C16.176 21 15.78 20.836 15.489 20.544V20.544Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'invalid'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 11V7.828C19 7.298 18.789 6.789 18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22 17.5C22 19.437 20.437 21 18.5 21C16.563 21 15 19.437 15 17.5C15 15.563 16.563 14 18.5 14C20.437 14 22 15.563 22 17.5Z" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.026 19.974L20.974 15.026" stroke="currentColor" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'expired'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0025 9.99917V7.82626C18.0025 7.29604 17.7914 6.78683 17.4162 6.41167L14.5871 3.58249C14.2119 3.20734 13.7027 2.99625 13.1725 2.99625H5.99749C4.89203 2.99625 3.99666 3.89162 3.99666 4.99708V19.0029C3.99666 20.1084 4.89203 21.0037 5.99749 21.0037H9.99916" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.0025 7.99833H14.0008C13.4486 7.99833 13.0004 7.55015 13.0004 6.99792V2.99625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.5019 22.0042C14.0158 22.0042 12 19.9883 12 17.5023C12 15.0163 14.0158 13.0004 16.5019 13.0004C18.9889 13.0004 21.0037 15.0163 21.0037 17.5023C21.0037 19.9883 18.9889 22.0042 16.5019 22.0042" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.5029 17.3352V15.6835" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.5019 19.158C16.4569 19.158 16.4198 19.195 16.4208 19.24C16.4208 19.285 16.4579 19.322 16.5029 19.322C16.5479 19.322 16.5839 19.285 16.5839 19.24C16.5839 19.195 16.5479 19.158 16.5019 19.158" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'graph'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.396 7.99997C16.614 7.99897 16.79 7.82197 16.79 7.60497C16.79 7.38697 16.613 7.21097 16.395 7.21097C16.177 7.21097 16 7.38697 16 7.60497C16 7.82297 16.177 7.99997 16.395 7.99997" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.396 3.77002C16.603 3.76902 16.77 3.60102 16.77 3.39402C16.77 3.18702 16.602 3.02002 16.395 3.02002C16.188 3.02002 16.02 3.18802 16.02 3.39502C16.02 3.60202 16.188 3.77002 16.396 3.77002" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.98 7.60398C20.98 7.39698 20.812 7.22998 20.605 7.22998C20.398 7.22998 20.231 7.39798 20.231 7.60498C20.231 7.81198 20.399 7.97998 20.606 7.97998C20.812 7.97998 20.98 7.81198 20.98 7.60398" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 21V3C7.029 3 3 7.029 3 12C3 16.971 7.029 21 12 21C16.971 21 21 16.971 21 12H12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'analytics'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 21H7C4.791 21 3 19.209 3 17V7C3 4.791 4.791 3 7 3H17C19.209 3 21 4.791 21 7V17C21 19.209 19.209 21 17 21Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 15L10 10L14 14L21 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'return'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 6H9C5.686 6 3 8.686 3 12C3 13.912 3.897 15.611 5.29 16.71" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 18H15C18.314 18 21 15.314 21 12C21 10.088 20.103 8.389 18.71 7.29" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.5 15.5L9 18L11.5 20.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5 8.5L15 6L12.5 3.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'upsell'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 17L20 19L18 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 19H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9899 8.97485V8.00001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9899 15V16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.2774 14.2856C10.5285 14.7226 10.9921 14.9942 11.4961 14.9995H12.5825C13.2972 15.0078 13.9037 14.4768 13.9898 13.7671C14.076 13.0575 13.6142 12.3968 12.9183 12.2338L11.0675 11.7628C10.3202 11.5802 9.86006 10.8291 10.0368 10.0804C10.1803 9.44549 10.7464 8.99598 11.3974 9.00003H12.4837C12.9882 9.00462 13.4524 9.27635 13.7034 9.71396" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.7676 14C20.9207 13.3443 20.9987 12.6733 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="icon === 'gear'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9 15.6468L5.298 15.8608C6.33 16.0188 7.067 16.9428 6.991 17.9838L6.888 19.3948C6.858 19.8058 7.082 20.1928 7.453 20.3718L8.487 20.8698C8.858 21.0488 9.301 20.9818 9.604 20.7028L10.643 19.7428C11.409 19.0348 12.591 19.0348 13.358 19.7428L14.397 20.7028C14.7 20.9828 15.142 21.0488 15.514 20.8698L16.55 20.3708C16.92 20.1928 17.143 19.8068 17.113 19.3968L17.01 17.9838C16.934 16.9428 17.671 16.0188 18.703 15.8608L20.101 15.6468C20.508 15.5848 20.836 15.2798 20.928 14.8778L21.183 13.7598C21.275 13.3578 21.112 12.9408 20.772 12.7088L19.605 11.9098C18.744 11.3198 18.481 10.1678 19.001 9.26278L19.706 8.03678C19.911 7.67978 19.877 7.23278 19.62 6.91078L18.905 6.01378C18.648 5.69178 18.22 5.55878 17.826 5.67978L16.474 6.09378C15.475 6.39978 14.41 5.88678 14.026 4.91578L13.508 3.60278C13.356 3.21878 12.985 2.96678 12.572 2.96778L11.426 2.97078C11.013 2.97178 10.643 3.22578 10.493 3.61078L9.988 4.90878C9.608 5.88578 8.538 6.40278 7.536 6.09478L6.128 5.66278C5.733 5.54078 5.303 5.67478 5.046 5.99878L4.336 6.89678C4.079 7.22178 4.048 7.66978 4.257 8.02678L4.978 9.25578C5.509 10.1618 5.249 11.3248 4.383 11.9178L3.23 12.7078C2.89 12.9408 2.727 13.3578 2.819 13.7588L3.074 14.8768C3.165 15.2798 3.493 15.5848 3.9 15.6468V15.6468Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.916 10.0835C14.974 11.1415 14.974 12.8575 13.916 13.9155C12.858 14.9735 11.142 14.9735 10.084 13.9155C9.026 12.8575 9.026 11.1415 10.084 10.0835C11.142 9.02554 12.858 9.02554 13.916 10.0835" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  data () {
    return {
      colors: {
        contrast: '#334155FF',
        primary: '#64748BFF',
        brand: '#2FB490FF',
        brandContrast: '#068373FF',
        light: '#CBD5E1FF',
        secondary: '#94A3B8FF',
        warning: '#A58012FF',
        warningLight: '#E6AC00',
        warningOrange: '#FF7E20FF',
        danger: '#EB5454FF',
        active: '#0284c7FF',
        invert: '#FFFFFFFF',
        gray: '#282A3A',
      }
    }
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'currentColor'
    },
    size: {
      type: String,
      default: '24'
    }
  },
  computed: {
    resolvedColor () {
      if (this.color === 'currentColor') return this.color
      return this.colors[this.color] || this.colors.primary
    },
    iconStyle () {
      return {
        color: this.resolvedColor,
        width: this.size + 'px',
        height: this.size + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
