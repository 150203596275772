import Vue from 'vue'
import Router from 'vue-router'
import login from '../pages/login/Login'
import firebase from 'firebase'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: 'Entrar',
      public: true
    },
    component: login
  },
  {
    path: '/login/',
    name: 'login',
    meta: {
      title: 'Entrar',
      public: true

    },
    component: login
  },
  {
    path: '/monitor/:searchValue?',
    name: 'monitor',
    meta: {
      title: 'Monitoramento'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/monitor/Monitoring')
  },
  {
    path: '/management/',
    name: 'management',
    meta: {
      title: 'Gestão'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/management/Management')
  },
  {
    path: '/evaluation/:uid?/:evaluationUid?',
    name: 'evaluation',
    meta: {
      title: 'Avaliação da Ligação',
      public: true
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/monitor/CallEvaluation')
  },
  {
    path: '/users/:searchValue?',
    name: 'users',
    meta: {
      title: 'Clientes'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/users/Users')
  },
  {
    path: '/questions/',
    name: 'questions',
    meta: {
      title: 'Perguntas Frequentes'
    },
    component: () => import(/* webpackChunkName: "Central" */ '../pages/questions/Central')
  },
  {
    path: '/trainings/',
    name: 'trainings',
    meta: {
      title: 'Treinamentos'
    },
    component: () => import(/* webpackChunkName: "Central" */ '../pages/trainings/Trainings')
  },
  {
    path: '/finance/',
    name: 'finance',
    meta: {
      title: 'Financeiro'
    },
    component: () => import(/* webpackChunkName: "Central" */ '../pages/finance/Finance')
  },
  // {
  //   path: '/settings/',
  //   name: 'settings',
  //   meta: {
  //     title: 'Configurações'
  //   },
  //   component: () => import(/* webpackChunkName: "Central" */ '../pages/settings/Settings')
  // }
  {
    path: '/settings/',
    name: 'settings',
    meta: {
      title: 'Configurações'
    },
    beforeEnter: (to, from, next) => {
      Vue.$toast.success('Em breve.');
      next(false);
    },
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'SimplesHub | ' + to.meta.title
  document.public = to.meta.public
  if (to.matched.some(record => !record.meta.public)) {
    if (!firebase.auth().currentUser) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }

  // if (!window.uid && !to.meta.public) {
  //   next({ name: 'login' })
  // } else {
  //   next()
  // }
})

export default router
