<template>
  <div class="field-flex" :style="grow ? 'flex-grow: 1' : ''">
    <div v-if="label" class="d-flex w-100 flex-row justify-content-between">
      <label class="menu-sm text-color-subtle" :class="{ strongtitle: strongtitle }">{{ label }}</label>
      <span v-if="showCharacterLimit" class="small text-color-subtle"> {{ characterCount }}/{{ characterLimit }} </span>
    </div>
    <div class="input-wrapper">
      <i v-if="locked" class="fas fa-lock lock-icon"></i>
      <input @blur="onBlur" v-mask="maskVal" class="custom-input" :class="{ 'input-locked': locked }" v-model="internalValue" :type="currentInputType"
        :placeholder="placeholder" :maxlength="characterLimit" :ref="customRef" :required="isRequired"
        :readonly="readonly" :disabled="locked" v-bind="$attrs" />
      <button v-if="inputType === 'password'" type="button" class="toggle-password" 
        @mousedown="showPassword = true"
        @mouseup="showPassword = false"
        @mouseleave="showPassword = false">
        <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: String,
    placeholder: String,
    characterLimit: Number,
    showCharacterLimit: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    customRef: String,
    inputType: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    mask: {
      type: Boolean,
      default: false
    },
    maskVal: {
      type: String,
      default: ''
    },
    grow: Boolean,
    strongtitle: Boolean,
    locked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalValue: this.value,
      showPassword: false
    }
  },
  methods: {
    onBlur (event) {
      const inputValue = event.target.value
      this.$emit('blur', inputValue)
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  watch: {
    value (newValue) {
      this.internalValue = newValue
    },
    internalValue (newValue) {
      this.$emit('input', newValue)
    }
  },
  computed: {
    characterCount () {
      return this.internalValue.length
    },
    isRequired () {
      return this.required
    },
    isReadonlyVal () {
      return this.readonly
    },
    currentInputType() {
      if (this.inputType === 'password') {
        return this.showPassword ? 'text' : 'password';
      }
      return this.inputType;
    }
  }
}
</script>

<style scoped lang="scss">
.field-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.toggle-password {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-detail);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--text-primary);
  }

  i {
    font-size: 16px;
  }
}

.custom-input {
  height: 40px !important;
  padding: 0 12px !important;
  width: 100%;
  border-radius: var(--border-radius-small, 4px) !important;
  border: 1px solid var(--stroke-heavy-contrast, #cbd5e1) !important;
  background: var(--surface-primary, #fff);
  box-shadow: none !important;
  font-weight: 400;
  font-size: 14px;
  color: var(--text-primary);

  &.input-locked {
    padding-left: 36px !important;

    .input-wrapper:has(.toggle-password) & {
      padding-right: 36px !important;
    }
  }

  .input-wrapper:has(.toggle-password) & {
    padding-right: 36px !important;
  }

  &:focus {
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.09) !important;
    outline: none !important;
  }
}

::placeholder {
  opacity: 1 !important;
  color: var(--text-detail) !important;
  font-weight: 400;
  font-size: 14px;
}

.strongtitle{
  font-size: 14px !important;
  margin: 0;
  font-weight: 700 !important;
  color: var(--text-color-primary) !important;
}

.lock-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-detail);
  font-size: 14px;
  z-index: 1;
}

.input-locked {
  padding-left: 36px !important;
  background-color: var(--surface-disabled, #f8fafc) !important;
  cursor: not-allowed;

  &::placeholder {
    color: var(--text-detail) !important;
  }
}
</style>
